/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"
import store from "@/store/store";
import Swal from 'sweetalert2'
import {
  convertToArray
} from '@/utils'
// const con = convertToArray();

export default {


  getCategoryObject() {
    var pg = store.getters["productGroup/productGroup"];

    var category = [];

    Object.assign(category, store.getters["productGroup/categories"]);

    var res = category.filter(x => x.code === pg.category);

    if (res.length > 0) {
      return res[0];
    } else {
      return null;
    }
  },

  getTextureObject() {
    var pg = store.getters["productGroup/productGroup"];
    var texture = store.getters["productGroup/textures"];
    var res = texture.filter(x => x.code === pg.texture);
    if (res.length > 0) {
      return res[0];
    } else {
      return null;
    }
  },

  getColors() {
    var pg = store.getters["productGroup/productGroup"];
    var selectedList = [];
    var list = store.getters["productGroup/colors"];
    var selected = pg.color.split("|");

    // console.log(' pg.color.split("|"); ',selected);

    selected.forEach(x => {
      if(x !== null && x !== undefined && x !== "" )
      {
          var res = list.filter(a => a.code === x);
          if (res.length > 0) {
            selectedList.unshift(res[0]);
          }else{
            // x.description = "ไม่ระบุ";
            selectedList.unshift(
              {
                "code" : x,
                "description" : "ไม่พบ (" + x + ")"
              }
            );
          }
      }
    }


    );

    return selectedList;

  },

  getSizes() {
    var pg = store.getters["productGroup/productGroup"];
    var selectedList = [];
    var list = store.getters["productGroup/sizes"];
    var selected = pg.size.split("|");


      selected.forEach(x => {
          if(x !== null && x !== undefined && x !== "" )
          {
              var res = list.filter(a => a.code === x);
              if (res.length > 0) {
                selectedList.unshift(res[0]);
              }else{
                // x.description = "ไม่ระบุ";
                selectedList.unshift(
                  {
                    "code" : x,
                    "description" : "ไม่พบ (" + x + ")"
                  }
                );
              }
          }

        }

      );


    return selectedList;

  },

  async getAllInfo({
    commit
  }) {


    // add productgroup และ product list


    var res = null;
    try {
      res = await axios.post(store.state.apiURL + "/api/productgroup/all-info" , {}, store.state.auth.apiHeader);
      commit('SET_DATA', res.data);

    } catch (err) {

      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถรับข้อมูลได้',
        // text: 'Something went wrong!',
      });



      res = null
    }
  },


  async getAllData({
    commit
  }, item) {


    // add productgroup และ product list


    var res = null;
    try {
      res = await axios.post(store.state.apiURL + "/api/productgroup/all-list/" + item, {}, store.state.auth.apiHeader);
      commit('SET_DATA', res.data);

    } catch (err) {

      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถรับข้อมูลได้',
        // text: 'Something went wrong!',
      });



      res = null
    }
  },


  addProductGroup() {

  },

  async saveChange({
    commit
  }, item) {

    var productGroup = item.productGroup;

    // เปลี่ยน productList ให่เป็น productGroup :


    item.productList.forEach((x) => {
      x.name = item.productGroup.name;
      x.code = item.productGroup.code;
      x.category = item.productGroup.category;
      x.texture = item.productGroup.texture;

    });

    productGroup.productItem = item.productList;
    var colors = convertToArray(item.productGroup.colors, "code");
    var sizes = convertToArray(item.productGroup.sizes, "code");

    productGroup.color = colors.join("|");
    productGroup.size = sizes.join("|");

    var resp = null;

    // add productgroup และ product list
    try {
      resp = await axios.post(store.state.apiURL + "/api/productgroup/create-all", productGroup, store.state.auth.apiHeader);
      commit('SET_ITEM', productGroup);

    } catch (err) {
      resp = err.response;
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถรับข้อมูลได้',
        // text: 'Something went wrong!',
      });
    }
    return resp;

  },

  async updateChange({
    commit
  }, item) {

    var productGroup = item.productGroup;

    // เปลี่ยน productList ให่เป็น productGroup :

    // console.log(productGroup.image);

    item.productList.forEach((x) => {
      x.name = item.productGroup.name;
      // x.code = item.productGroup.code;
      x.category = item.productGroup.category;
      x.texture = item.productGroup.texture;

    });

    productGroup.productItem = item.productList;
    var colors = convertToArray(item.productGroup.colors, "code");
    var sizes = convertToArray(item.productGroup.sizes, "code");

    productGroup.color = colors.join("|");
    productGroup.size = sizes.join("|");



    // add productgroup และ product list
    try {
      await axios.post(store.state.apiURL + "/api/productgroup/update-all/" + productGroup.productGroupId, productGroup, store.state.auth.apiHeader);
      commit('SET_ITEM', productGroup);

    } catch (err) {

      // console.log(err.response.state);

      if (err.response.data.code === "USED_ITEM")
      {

              Swal.fire({
                  title: "<strong>มีบางรายการกำลังออกบิลอยู่</strong>",
                  // html: "ข้อมูลทั้งหมดที่ลงไว้จะถูก <b>ลบทั้งหมด</b> ",

                  icon: "warning",
                  // showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  // cancelButtonColor: "#d33",
                  confirmButtonText: "ลบข้อมูลทั้งหมด"
                }).then(async result => {
                  if (result.value) {
                    location.reload();
                  }
            });
            // Swal.fire({
            //         icon: 'warning',
            //         title: "มีสินค้าถูกออกบิลและกำลังอยู่ในสถานะ [รอรับชุด] หรือ [รอคืนชุด]",
            //         // text: err.response.state,
            //       });
      }

    }

  },


  async updateOnlyProductGroup({
    commit
  }, item) {
        // add productgroup และ product list



        var res = null;
        try {
          res = await axios.put(store.state.apiURL + "/api/productgroup/update-only-productgroup/" + item.productGroupId, item, store.state.auth.apiHeader);
          commit('SET_ITEM', item);
          return res;

        } catch (err) {

          Swal.fire({
            icon: 'error',
            title: 'ไม่สามารถอัพเดตข้อมูลได้',
            text: err.response.state,
          });
          return null;
        }
  },



  async removeAll({
    commit
  }, item) {


    // เปลี่ยน productList ให่เป็น productGroup :
    // add productgroup และ product list
    var res = null;
    try {
      res = await axios.post(store.state.apiURL + "/api/productgroup/remove-all/" + item, {}, store.state.auth.apiHeader);
      commit('REMOVE_ALL');
      return res;

    } catch (err) {

      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถลบข้อมูลได้',
        text: err.response.state,
      });
    }

    return null;

  },

  async create({
    commit
  }, item) {

    // เช็คก่อนว่ามี Record ค้างอยู่รึเปล่า
    // var check = null;
    // try {
    //   await axios.post(store.state.apiURL + "/api/productgroup/check-new-record", {}, store.state.auth.apiHeader);
    //   // if (check.data.result === "success") {
    //   //   commit("SET_ITEM", check.data.response);
    //   //   return check.data.response;

    //   // }
    // } catch (err) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'ไม่สามารถรับข้อมูลได้',
    //     // text: 'Something went wrong!',
    //   });
    //   // check = null;

    // }

    // add productgroup และ product list
    var res = {};
    try {
      res = await axios.post(store.state.apiURL + "/api/productgroup", item, store.state.auth.apiHeader)
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถสร้างข้อมูลได้',
        // text: 'Something went wrong!',
      });
      res = null;
    }

    commit("SET_ITEM", res.data);
    return res.data;
  },

  async set({
    commit
  }, id) {

    var item = {};
    try {
      item = await axios.get(store.state.apiURL + "/api/productgroup/" + id, store.state.auth.apiHeader)
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถเรียกดูข้อมูลได้',
        // text: 'Something went wrong!',
      });

    }

    commit("SET_ITEM", item.data);
    return item.data;

  },

  fetch({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get(store.state.apiURL + "/api/productitem", store.state.auth.apiHeader)
        .then((response) => {
          commit('SET_ITEM', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // fetchEventLabels({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios.get("/api/apps/calendar/labels")
  //       .then((response) => {
  //         commit('SET_LABELS', response.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  update({
    commit
  }, item) {
    return new Promise((resolve, reject) => {
      axios.put(store.state.apiURL + "/api/productitem/" + item, item, store.state.auth.apiHeader)
        .then((response) => {
          commit('UPDATE_PRODUCT', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  remove({
    commit
  }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(store.state.apiURL + "/api/productitem/" + itemId, store.state.auth.apiHeader)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
