
import axios from "@/axios.js"
import store from "@/store/store";
import Swal from 'sweetalert2'

export default {





  async saveChange() {
    // commit('SAVE_CHANGE');
    var formData = new FormData();
    // console.log('*********** images *********** ', store.state.duration.images);


    for (var i = 0; i < store.state.duration.images.length; i++) {
      formData.append('files[' + i + ']', store.state.duration.images[i]);
    }

    try {
      await axios.post(
        store.state.apiURL + "/api/test-upload/" + "XXXXX-XXXXXXX-XXX",
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        // store.state.auth.apiHeader
      );
    } catch (err) {
      console.log('err >>> ', err);

      Swal.fire({
        icon: 'error',
        title: 'มียางอย่างผิดพลาด',
        // text: 'Something went wrong!',
      });
    }


  },
  add({
    commit
  }, item) {
    commit('ADD_ITEM', item)
    console.log('PRODUCT_GROUP IN PRODUCT_LIST >>> ', store.state.productGroup.productGroup);


  },

  clearParm({
    commit
  }) {

    commit('CLEAR_SEARCH_PARAM')
  },

  searchParm({
    commit
  }, item) {

    commit('ADD_SEARCH_PARAM', item)
  }
  ,

  addSelected({
    commit
  }, item) {
    commit('ADD_SELECTED', item);

    var selected = store.getters["duration/selected"];

    var fil = selected.filter(x => x.productItemId === item.productItemId);

    if (fil.length > 0) return true;
    else return false;

  },

  selectedAll({
    commit
  }, list) {

    commit('SELECTED_All', list);
  },

  unSelectedAll({
    commit
  }, list) {

    commit('UNSELECTED_All', list);
  },

  clearAll({
    commit
  }) {
    console.log('ACTIONNNNN :REMOVE CLEAR ALL ');

    commit('CLEAR_ALL');
  },


  // checkSelected({
  //   commit
  // }, item) {
  //   commit('CHECK_SELECTED', item);
  // },

  applyAll({
      commit
    },
    item) {
    commit('APPLY_ALL', item);
    console.log('item in APPLY  ALL ', item);

    console.log(store.getters["duration/products"]);

  },

  async addImage({
    commit
  }, item) {
    commit('ADD_IMAGE', item)

  },
  async fetchColorList({
    commit
  }, item) {
    var res = await axios.post(
      store.state.apiURL +
      "/api/productgroup/load-color/" +
      item, {},
      store.state.auth.apiHeader
    );

    // console.log("##########fetchColorList ############# >>>>> ",res);
    commit('SET_IMAGE', res.data)
  },
  fetchList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get(store.state.apiURL + "/api/productitem", store.state.auth.apiHeader)
        .then((response) => {
          commit('SET_PRODUCTS', response.data)
          resolve(response)
        })
        .catch((error) => {
          this.$swal({
            icon: 'error',
            title: 'ไม่สามารถรับข้อมูลได้',
          });
          reject(error)
        })
    })
  },
  listInGroup({
    commit
  }, item) {
    return new Promise((resolve, reject) => {
      axios.post(store.state.apiURL + "/api/productitem/group/" + item, {}, store.state.auth.apiHeader)
        .then((response) => {
          commit('SET_PRODUCTS', response.data)
          // console.log('listInGroup ??? response.data', response.data);

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateList({
    commit
  }, list) {

    commit('SET_PRODUCTS', list)

  },

  update({
    commit
  }, item) {
    return new Promise((resolve, reject) => {
      axios.put(store.state.apiURL + "/api/productitem/" + item, item, store.state.auth.apiHeader)
        .then((response) => {
          commit('UPDATE_PRODUCT', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  remove({
    commit
  }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(store.state.apiURL + "/api/productitem/" + itemId, store.state.auth.apiHeader)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeImage({
    commit
  }, item) {
    commit('REMOVE_IMAGE', item)
    console.log('IMAGE IN PRODUCT_LIST >>> ', store.state.productGroup.productGroup);


  },

  removeAll({
    commit
  }) {
    // console.log('REMOVE_ALL >>>***(**(');

    commit('REMOVE_ALL')

  },

}
