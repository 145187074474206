/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


// import auth from "@/auth/authService";
// import firebase from 'firebase/app'
// import 'firebase/auth'

export default {

  isUserLoggedIn: !!localStorage.getItem('token'),
  isAuthen: false,
  currentUser: null,
  currentUserImage: null,
  currentPackage: 's',
  roles: [],
  permission: 'staff',
  mybranch: '1',
  mybranchid: null,
  myBranches: [],
  currentBranch: null,
  apiHeader: {},
  occupiedBranches: [],



}
