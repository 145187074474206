/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

// import moduleTodo from './todo/moduleTodo.js'
// import moduleCalendar from './calendar/moduleCalendar.js'
// import moduleChat from './chat/moduleChat.js'
// import moduleEmail from './email/moduleEmail.js'
import moduleAuth from './auth/moduleAuth.js'
import moduleECommerce from './eCommerce/moduleECommerce.js'
import moduleProductGroup from './product-group/moduleProductGroup.js'
import moduleMonthlyChart from './monthly-chart/moduleMonthlyChart.js'
import moduleProductList from './product-list/moduleProductList.js'
import moduleWashingList from './washing-list/moduleWashingList.js'
import moduleShop from './shop/moduleShop.js'
import moduleWnfShop from './wnfShop/moduleWnfShop.js'
import moduleWnfOrder from './wnf-order/moduleWnfOrder.js'
import moduleCart from "./cart/cartIndex";
import moduleCartSale from "./cartSale/cartSaleIndex";
import moduleSidebar from "./sidebar/sidebarIndex";
import moduleCommon from "./commonModule";
import moduleDuration from "./duration/moduleDuration.js";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    // todo: moduleTodo,
    // calendar: moduleCalendar,
    // chat: moduleChat,
    // email: moduleEmail,
    monthlyChart: moduleMonthlyChart,
    productList: moduleProductList,
    productGroup: moduleProductGroup,
    shop: moduleShop,
    auth: moduleAuth,
    washingList: moduleWashingList,
    wnfShop: moduleWnfShop,
    wnfOrder: moduleWnfOrder,
    eCommerce: moduleECommerce,
    cart: moduleCart,
    cartSale: moduleCartSale,
    sidebar: moduleSidebar,
    common: moduleCommon,
    duration: moduleDuration,
  },
  strict: process.env.NODE_ENV !== 'production'
})
