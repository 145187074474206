/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  monthName: state => state.monthName,
  rentalPrice : state => state.rentalPrice,
  yearlist: state => state.yearlist,
  category: state => state.category,
  selling : state => state.selling,
  yearlistCategory : state => state.yearlistCategory,
  monthlistCategory : state => state.monthlistCategory,
  allRental : state => state.allRental,
  allOrderNumber : state => state.allOrderNumber,
  allNotReturnedDeposit : state => state.allNotReturnedDeposit,

}



