/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  // getItem: state => (productId) => state.products.find((product) => product.id == productId),
  basket: state => state.basket,
  display: state => state.display,
  wnfDetail: state => state.wnfDetail,
  displayPopup: state => state.displayPopup,
   updateItem: state => state.updateItem,
  anOrder: state => state.anOrder,
  searchParameters: state => state.searchParameters,
  selected: state => state.selected,
  selectedNumber: state => () => {
    return state.selected.length;
  },

}
