/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  // getItem: state => (productId) => state.products.find((product) => product.id == productId),
  products: state => state.products,
  searchParameters: state => state.searchParameters,
  selected: state => state.selected,
  catalog: state => state.catalog,
  catalogPanel: state => state.catalogPanel,
 
  selectedNumber: state => () => {
    return state.selected.length;
  },
  images: state => state.images,
  getColor: state => (gruopId, color) => {
    var result = state.images.filter((item) => item.productGroupId == gruopId && item.color == color);
    if (result.length > 0) {
      return result[0].fileName;
    } else {
      return null;
    }
  },

  somethingChange: state => state.somethingChange,
  
}
