/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.productGroup.unshift(item)
  },
  SET_DATA(state, item) {
    state.monthName = item.month;
    state.rentalPrice = item.rentalPrice;
    state.yearlist = item.yearlist;
  },
  SET_SELLING_DATA(state, item) {
    state.category = item.category;
    state.selling = item.rentalPrice;
    state.yearlistCategory = item.yearlistCategory;
    state.monthlistCategory = item.monthlistCategory;
    state.allRental = item.allRental;
    state.allOrderNumber = item.allOrderNumber;
    state.allNotReturnedDeposit = item.allNotReturnedDeposit;
  },

  SET_ITEM(state, item) {
    Object.assign(state.productGroup, item)
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_ITEM(state, product) {
    const productIndex = state.productGroup.findIndex((p) => p.id == product.id)
    Object.assign(state.productGroup[productIndex], product)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.productGroup.findIndex((p) => p.id == itemId)
    state.productGroup.splice(ItemIndex, 1)
  },

  REMOVE_ALL(state) {
    state.productGroup = []
  },
}
