/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  // SAVE_CHANGE(state) {

  // },
  ADD_OR_UPDATE(state, item) {
    // 0: NOT_SET  1 : add  2 : update
    state.add_or_update = item;
  },

  UPDATE_ITEM(state, item) {
    state.updateItem = item;
  },

  ADD_ITEM(state, item) {
    state.basket.unshift(item);

  },

  UPDATE_PRODUCT(state, item) {
    
    var temp = state.basket;
    state.basket = [];

    const productIndex = temp.findIndex(p => p.wnfShopId === item.wnfShopId);
    temp[productIndex] = item;

    state.basket = temp;



  },

  ADD_IMAGE(state, item) {

    state.images.unshift(item);


  },


  ADD_SEARCH_PARAM(state, item) {
    Object.assign(state.searchParameters, item);
  },
  CLEAR_SEARCH_PARAM(state) {
    state.searchParameters = {};
  },

  ADD_SELECTED(state, item) {

    var fil = state.selected.filter(x => x.orderDetailId === item.orderDetailId);
    if (fil.length < 1) {
      state.selected.push(item);
    } else {
      const ItemIndex = state.selected.findIndex((p) => p.orderDetailId == item.orderDetailId)
      state.selected.splice(ItemIndex, 1);
    }

    // console.log('@@@@@@@ MUTUAL >> state.selected ', state.selected);

  },

  SELECTED_All(state, list) {
    var indList = [];
    state.selected.forEach((ele, index) => {
      var chk = list.filter(x => x.orderDetailId === ele.orderDetailId);
      if (chk.length > 0) {
        indList.push(index);
      }
    });


    for (let ind = indList.length - 1; ind >= 0; ind--) {
      state.selected.splice(indList[ind], 1);

    }

    state.selected.push(...list);

  },

  UNSELECTED_All(state, list) {
    var indList = [];
    state.selected.forEach((ele, index) => {
      var chk = list.filter(x => x.orderDetailId === ele.orderDetailId);
      if (chk.length > 0) {
        indList.push(index);
      }
    });

    for (let ind = indList.length - 1; ind >= 0; ind--) {
      state.selected.splice(indList[ind], 1);

    }

  },

  CLEAR_ALL(state) {
    state.selected = [];
    state.selectedNumber = 0;

  },


  //   // console.log('ITEM ++++++++++++++++++++++++++ ', fil);
  //   // console.log('ITEM ++++++++++++++++++++++++++ ', state.selected);

  // },

  SET_PRODUCTS(state, basket) {
    Object.assign(state.basket, basket.itemList);
    Object.assign(state.display, basket)

    // console.log("state.display >>> ",state.display);
  },

  SET_A_STATUS(state, item) {
    // Object.assign(state.basket, basket.itemList);
    // Object.assign(state.display, basket)

    console.log("state.display >>> ",item);
  },

  POP_PANEL(state, item) {
    // Object.assign(state.basket, basket.itemList);
    // Object.assign(state.display, basket)
    state.popPanel = item;
  },



  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },

  REMOVE_IMAGE(state, item) {
    state.images.unshift(item)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.basket.findIndex((p) => p.id == itemId)
    state.basket.splice(ItemIndex, 1)
  },
  REMOVE_ALL(state) {
    state.basket = [];
  },
}
