export default {
    addCartItem(context, data) {
        context.commit("addCartItem", data);
        context.commit("updateCartTotalPrice");
    },
    removeCartItem(context, data) {
        context.commit("removeCartItem", data);
        context.commit("updateCartTotalPrice");
    },
    updateCartTotalPrice(context) {
        context.commit('updateCartTotalPrice');
    },
    updateQtyCart(context, data) {
        context.commit('updateQtyCart', data);
        context.commit('updateCartTotalPrice');
    },
    triggerProductNotAvailable(context, data) {
        context.commit('triggerProductNotAvailable', data);
    },
    updateCartMobileReserveCheck(context, data) {
      context.commit('updateCartMobileReserveCheck', data);
    },
    updateCartMobileReserveDate(context, data) {
      context.commit('updateCartMobileReserveDate', data)
    }
};
