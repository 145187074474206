

import axios from "@/axios.js"
import store from "@/store/store";
import Swal from 'sweetalert2'

export default {
  async saveChange() {
    // commit('SAVE_CHANGE');
    var formData = new FormData();
    // console.log('*********** images *********** ', store.state.productList.images);


    for (var i = 0; i < store.state.productList.images.length; i++) {
      formData.append('files[' + i + ']', store.state.productList.images[i]);
    }

    try {
      await axios.post(
        store.state.apiURL + "/api/test-upload/" + "XXXXX-XXXXXXX-XXX",
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        // store.state.auth.apiHeader
      );
    } catch (err) {
      console.log('err >>> ', err);

      Swal.fire({
        icon: 'error',
        title: 'มียางอย่างผิดพลาด',
        // text: 'Something went wrong!',
      });
    }


  },


  add({
    commit
  }, item) {
    return new Promise((resolve, reject) => {
      axios.post(store.state.apiURL + "/api/v2/orderheader/clean-fix/addWnfShop", item, store.state.auth.apiHeader)
        .then((response) => {
          commit('ADD_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
          Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถบันทึกข้อมูลได้',
          });
          
        })
    });


  },
 update({
    commit
  }, item) {
    return new Promise((resolve, reject) => {
      axios.put(store.state.apiURL + "/api/v2/orderheader/clean-fix/updateWnfShop", item, store.state.auth.apiHeader)
        .then((response) => {
          commit('UPDATE_PRODUCT', item)
          resolve(response)
        })
        .catch((error) => {
          Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถบันทึกข้อมูลได้',
          });
          
          reject(error)
        })
    })
  },

  async sendToWash({
      commit
  }, item) {

        var res = null;
        try {
          res = await axios.post(
           store.state.apiURL + "/api/v2/orderheader/clean-fix/send-to-wnf",
            item,
            store.state.auth.apiHeader
          );

           commit('SET_PRODUCTS', res.data);

        } catch (error) {
          res = null;
           Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        // text: 'Something went wrong!',
      });

        }
    },  

  async receiveFromWash({
      commit
  }, item) {

        var res = null;
        try {
          res = await axios.post(
           store.state.apiURL + "/api/v2/orderheader/clean-fix/receive-from-wnf",
            item,
            store.state.auth.apiHeader
          );

           commit('SET_PRODUCTS', res.data);

        } catch (error) {
          res = null;
           Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        // text: 'Something went wrong!',
      });

        }
    },  
  async notToSend({
      commit
  }, item) {

        var res = null;
        try {
          res = await axios.post(
           store.state.apiURL + "/api/v2/orderheader/clean-fix/not-send-to-wnf",
            item,
            store.state.auth.apiHeader
          );

           commit('SET_PRODUCTS', res.data);

        } catch (error) {
          res = null;
           Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        // text: 'Something went wrong!',
      });

        }
    },  


  clearParm({
    commit
  }) {

    commit('CLEAR_SEARCH_PARAM')
  },

  searchParm({
    commit
  }, item) {

    commit('ADD_SEARCH_PARAM', item)
  }
  ,

  addSelected({
    commit
  }, item) {
    commit('ADD_SELECTED', item);

    var selected = store.getters["washingList/selected"];

    var fil = selected.filter(x => x.orderDetailId === item.orderDetailId);

    if (fil.length > 0) return true;
    else return false;

  },

  selectedAll({
    commit
  }, list) {

    commit('SELECTED_All', list);
  },

  unSelectedAll({
    commit
  }, list) {

    commit('UNSELECTED_All', list);
  },

  clearAll({
    commit
  }) {
    console.log('ACTIONNNNN :REMOVE CLEAR ALL ');

    commit('CLEAR_ALL');
  },



  applyAll({
      commit
    },
    item) {
    commit('APPLY_ALL', item);
    console.log('item in APPLY  ALL ', item);

    console.log(store.getters["washingList/basket"]);

  },

  async addImage({
    commit
  }, item) {
    commit('ADD_IMAGE', item)

  },
  async fetchColorList({
    commit
  }, item) {
    var res = await axios.post(
      store.state.apiURL +
      "/api/productgroup/load-color/" +
      item, {},
      store.state.auth.apiHeader
    );

    // console.log("##########fetchColorList ############# >>>>> ",res);
    commit('SET_IMAGE', res.data)
  },
  fetchList({
    commit
  }, params) {
    
    

    return new Promise((resolve, reject) => {
      axios.post(store.state.apiURL + "/api/v2/orderheader/clean-fix/getWnfShop",params, store.state.auth.apiHeader)
        .then((response) => {
          // console.log(" then  before SET_PRODUCTS >>>>> ",response.data );
          commit('SET_PRODUCTS', response.data)
          resolve(response)
        })
        .catch((error) => {
          this.$swal({
            icon: 'error',
            title: 'ไม่สามารถรับข้อมูลได้',
          });
          reject(error)
        })
    })
  },

  async changeStatus(
    { commit },
    item) {
    return new Promise((resolve, reject) => {
      axios.put(store.state.apiURL + "/api/v2/orderheader/clean-fix/statusWnfShop" , item, store.state.auth.apiHeader)
        .then((response) => {
          commit('SET_A_STATUS', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
    
    
  listInGroup({
    commit
  }, item) {
    return new Promise((resolve, reject) => {
      axios.post(store.state.apiURL + "/api/productitem/group/" + item, {}, store.state.auth.apiHeader)
        .then((response) => {
          commit('SET_PRODUCTS', response.data)
          // console.log('listInGroup ??? response.data', response.data);

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateList({
    commit
  }, list) {

    commit('SET_PRODUCTS', list)

  },

 
  remove({
    commit
  }, item) {
    // console.log("HELLO item ",item);
    return new Promise((resolve, reject) => {
      axios.post(store.state.apiURL + "/api/v2/orderheader/clean-fix/deleteWnfShop", item, store.state.auth.apiHeader)
        .then((response) => {
          commit('REMOVE_ITEM', item);
          
          resolve(response)
        })
        .catch((error) => {
          reject(error)
          Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถลบข้อมูลได้',
            text: error.response.data.message,
          });
          
        })
    });
  },

  removeImage({
    commit
  }, item) {
    commit('REMOVE_IMAGE', item)
    // console.log('IMAGE IN PRODUCT_LIST >>> ', store.state.productGroup.productGroup);


  },

  removeAll({
    commit
  }) {
    // console.log('REMOVE_ALL >>>***(**(');

    commit('REMOVE_ALL')

  },

}
