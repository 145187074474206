
import router from "@/router";
import store from "@/store/store";

import axios from "axios";
import Swal from 'sweetalert2'

export default {
  // >>>>> USER LOGIN
  async userLogin({
    commit
  }, payload) {

    var loginres = null;
    try {
      loginres = await axios.post(store.state.apiURL + "/api/user/login", {
        username: payload.userInfo.username,
        password: payload.userInfo.password
      });
    } catch (err) {
      // loginres = err.response;
      commit("USER_LOGIN_FAILED");
      // console.log('err >>> ', loginres);
      Swal.fire({
        icon: 'warning',
        title: "บัญชีผู้ใช้งานไม่ถูกต้อง",
        // text: 'Something went wrong!',
      });
      // if (loginres.status !== undefined) {
      //   if (loginres.status === 400 && loginres.data.code === "INCOMPLETE") {
      //     window.location.href = store.state.regURL + "/register/tell-about-your-store/" + loginres.data.description;
      //   }
      // }

    }



    if (loginres !== null) {
      if (loginres.status === 200) {
        localStorage.clear();

        localStorage.setItem("token", JSON.stringify(loginres.data.token));
        localStorage.setItem("username", JSON.stringify(loginres.data.username));
        localStorage.setItem("currentDomain", JSON.stringify(loginres.data.shopInfoId));
        localStorage.setItem(
          "userImage",
          JSON.stringify(loginres.data.userImage)
        );

        commit("SET_USER_IMAGE" ,loginres.data.userImage );
        commit("SHOP_PACKAGE" ,loginres.data.package );
        commit("CHANGE_CURRENT_BRANCH", loginres.data);


        localStorage.setItem("roles", JSON.stringify(loginres.data.roleName));
        localStorage.setItem(
          "tokenExpire",
          JSON.stringify(loginres.data.tokenExpire)
        );
        commit("USER_LOGIN_SUCCESS");
        store.dispatch("auth/getAuth");


        window.location.href = "/";
      } else {
        commit("USER_LOGIN_FAILED");
      }



    } else {
      commit("USER_LOGIN_FAILED");
      // payload.notify({
      //   title: "Login Failed",
      //   text: "Username หรือ Password ไม่ถูกต้อง!",
      //   iconPack: "feather",
      //   icon: "icon-alert-circle",
      //   color: "danger"
      // });
    }


  },

  changeUserImage({
    commit
  },item){
    commit("SET_USER_IMAGE" ,item );

    // console.log('@@@@@ auth/ChangeUserImage ACTION CHANGE USER IMAGE ');

  },

  async userLogout({
    commit
  }) {
    localStorage.clear();
    commit("USER_LOGOUT");
    router.push("/pages/login");
  },

  stillLogin({
    commit
  }) {
    commit("USER_LOGIN_SUCCESS");
  },
  alreadyLogout({
    commit
  }) {
    commit("USER_LOGIN_FAILED");
  },
  async checkAuthen({
    commit
  }) {
    var theToken = JSON.parse(localStorage.getItem("token"));
    var theCurrentUser = JSON.parse(localStorage.getItem("username"));
    var theCurrentUserImage = JSON.parse(localStorage.getItem("userImage"));

    var load = {
      token: theToken,
      username: theCurrentUser,
      image: theCurrentUserImage
    };
    // store.state.currentUser = JSON.parse(localStorage.getItem('username'));
    // store.state.currentUser = JSON.parse(localStorage.getItem('username'));
    // store.state.currentUserImage = JSON.parse(localStorage.getItem('userImage'));

    commit("UPDATE_AUTHENTICATED_USER", load);
    commit("SET_USER_IMAGE" ,theCurrentUserImage );

    if (theToken) {
      commit("USER_LOGIN_SUCCESS");
    } else {
      commit("USER_LOGIN_FAILED");
    }
    var config = {
      headers: {
        Authorization: "bearer " + theToken
      }
    };

    var res = await axios.post(
      store.state.apiURL + "/api/user/checkAuthen", {
        username: theCurrentUser,
        token: theToken
      },
      config
    );

    if (res.status === 200) {
      commit("USER_LOGIN_SUCCESS");

    } else {
      commit("USER_LOGIN_FAILED");
    }

  },

  async getAuth({
    commit
  }) {
    var tk = await localStorage.getItem("token");
    var un = await localStorage.getItem("username");

    await commit("UPDATE_TOKEN");

    // console.log('@@@@@@@@@@@@ getAuth Action @@@@@@@@@@@@@@');

    if (tk === null || tk === undefined) {
      return;
    }

    var load = {};
    var theroles = [{
      roleName: "staff"
    }];
    load = {
      roles: theroles,
      branches: null,
      allbranches: null
    };



    var theUsername = JSON.parse(un);


    // console.log("@@@@@ BEFORE TRY");

    var roleResp = null;
    try {
      roleResp = await axios.post(
        store.state.apiURL + "/api/user/getAuthorize", {
          username: theUsername
        },
        store.state.auth.apiHeader
      );
      // console.log("@@@@@ GETAUTH ACTION => TRY", roleResp);
    } catch (err) {
      roleResp = null;
      commit("USER_LOGIN_FAILED");
      return false;
    }

    var allBranches = [];
    try {
      allBranches = await axios.get(
        store.state.apiURL + "/api/branch",
        store.state.auth.apiHeader
      );
    } catch (error) {
      allBranches = [];

    }

    // console.log("@@@@@ GETAUTH ACTION !!!", roleResp);


    if (roleResp === null || roleResp === undefined) {
      load = {
        roles: [],
        branches: []
      };

      // console.log("COCCHIIIIII ", roleResp);
      commit("USER_LOGIN_FAILED");
      commit("UPDATE_ROLES", load);
      // commit("SET_USER_IMAGE" ,item );


      return false;
    }


    if (roleResp.status === 200) {
      load = {
        roles: roleResp.data.roleName,
        branches: roleResp.data.userBranch,
        allbranches: allBranches.data
      };
      commit("SHOP_PACKAGE" ,roleResp.data.package );
      commit("SET_USER_IMAGE" ,roleResp.data.userImage );
      commit("CHANGE_CURRENT_BRANCH", load);
      // console.log(' @@@@@@@@@ AUTH MODULE ::>>>>>>>>> ',load);


      commit("UPDATE_ROLES", load);

      // store.dispatch("updateUserRole", {
      //   aclChangeRole: this.$acl.change,
      //   userRole: 'admin'
      // });
      // console.log("****************** acl >>> ", acl)
      return true;
    } else {
      load = {
        roles: theroles,
        branches: null,
        allbranches: null
      };
      commit("USER_LOGIN_FAILED");
      // console.log('AFTER USER_LOGIN_FAILED');

      commit("UPDATE_ROLES", load);
      return false;

    }
  }

  ,

  async changeBranch({
    commit
  },branchId) {
    var tk = await localStorage.getItem("token");
    var un = await localStorage.getItem("username");
    // console.log("getAuth:: tk >>> ", tk);
    // var tkpaser = JSON.parse(tk);
    await commit("UPDATE_TOKEN");

    if (tk === null || tk === undefined) {
      return;
    }

    var load = {};
    var theroles = [{
      roleName: "staff"
    }];
    load = {
      roles: theroles,
      branches: null,
      allbranches: null
    };



    var theUsername = JSON.parse(un);


    // console.log("@@@@@ BEFORE TRY");

    var roleResp = null;
    try {
      roleResp = await axios.post(
        store.state.apiURL + "/api/user/getAuthorize", {
          username: theUsername
        },
        store.state.auth.apiHeader
      );
      // console.log("@@@@@ GETAUTH ACTION => TRY", roleResp);
    } catch (err) {
      roleResp = null;
      commit("USER_LOGIN_FAILED");
      return false;
    }

    var allBranches = [];
    try {
      allBranches = await axios.get(
        store.state.apiURL + "/api/branch",
        store.state.auth.apiHeader
      );
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถเรียกรายการสาขาได้',
      });
      allBranches = [];

    }

    console.log("@@@@@ GETAUTH ACTION !!!", roleResp);


    if (roleResp === null || roleResp === undefined) {
      load = {
        roles: [],
        branches: []
      };

      commit("USER_LOGIN_FAILED");
      commit("UPDATE_ROLES", load);

      return false;
    }


    if (roleResp.status === 200) {
      load = {
        roles: roleResp.data.roleName,
        branches: roleResp.data.userBranch,
        allbranches: allBranches.data,
        selectedBranchId: branchId
      };

      // console.log(' changeBranch >>>>  @@@@@@@@@ AUTH MODULE ::>>>>>>>>> ',load);


      commit("CHANGE_CURRENT_BRANCH", load);

      return true;
    } else {
      load = {
        roles: theroles,
        branches: null,
        allbranches: null
      };
      commit("USER_LOGIN_FAILED");
      // console.log('AFTER USER_LOGIN_FAILED');

      commit("UPDATE_ROLES", load);
      return false;

    }
  }
};
