/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  tax: {
      taxRate: 7,
      vatSystem: false,
      inVat: false,
      receiptHeader: "ใบเสร็จรับเงิน",
  },
  receipt: {
    settlementBox : true,
  },
  logappSelected: [],
  logappSelectedNumber: 0,

}
