/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  monthName: ["ม.ค.", "ก.พ." ,"มี.ค." , "เม.ย." ,"พ.ค." , "มิ.ย." ,"ก.ค." ,"ส.ค." , "ก.ย." , "ต.ค." , "พ.ย." ,  "ธ.ค." ],
  rentalPrice: [0,0,0,0,0,0,0,0,0,0,0,0],
  yearlist: [],
  category: [],
  selling: [],
  yearlistCategory: [],
  monthlistCategory : [],
  allRental : 0,
  allOrderNumber : 0,
  allNotReturnedDeposit : 0,



}
