/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  // ADD_ITEM(state, item) {
  //   state.products.unshift(item)
  // },
  // SET_PRODUCTS(state, products) {
  //   state.products = products
  // },

   SET_SHOP_SETTING(state, item) {
    state.tax.taxRate = item.taxRate;
    state.tax.vatSystem = item.vatSystem;
    state.tax.inVat = item.inVat;
    state.tax.receiptHeader = item.receiptHeader;
    // console.log('@@@MUTUAL state.tax ',state.tax);


  },
  UPDATE_SHOP_SETTING(state, item) {
    state.tax.taxRate = item.taxRate;
    state.tax.vatSystem = item.vatSystem;
    state.tax.inVat = item.inVat;
    state.tax.receiptHeader = item.receiptHeader;

  },

  CHANGE_SETTLEMENTBOX(state, item) {
    state.receipt.settlementBox = item;
    console.log('@@@MUTUAL state.receipt.settlementBox ',state.receipt.settlementBox);
  },
  // REMOVE_ITEM(state, itemId) {
  //   const ItemIndex = state.products.findIndex((p) => p.id == itemId)
  //   state.products.splice(ItemIndex, 1)
  // },



  ADD_SELECTED_LOGAPP(state, item) {

        var fil = state.logappSelected.filter(x => x[state.itemId] === item[state.itemId]);
        if (fil.length < 1) {
            state.logappSelected.push(item);
        } else {
          const ItemIndex = state.logappSelected.findIndex((p) => p[state.itemId] == item[state.itemId])
          state.logappSelected.splice(ItemIndex, 1);
        }

        // console.log("selected >>> ",state.logappSelected);
        // console.log("selected number >>> ",state.logappSelected.length);

    },
  SELECTED_All_LOGAPP(state) {
        let allItems = state.items;

    // if (list)
      if (state.logappSelected.length < state.items.length) {
             state.logappSelected = [];
              state.logappSelected.push(...allItems);
      } else {
         state.logappSelected = [];
      }
    

    },

  UNSELECTED_All_LOGAPP(state) {
        state.logappSelected = [];

  },
  CLEAR_ALL_LOGAPP(state) {
      state.logappSelected = [];
      state.logappSelectedNumber = 0;

  },


}
