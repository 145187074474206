/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  // getItem: state => (productId) => state.products.find((product) => product.id == productId),
  add_or_update : state => state.add_or_update,
  updateItem : state => state.updateItem,
  popPanel: state => state.popPanel,
  basket: state => state.basket,
  display: state => state.display,
  searchParameters: state => state.searchParameters,
  selected: state => state.selected,
  selectedNumber: state => () => {
    return state.selected.length;
  },
  images: state => state.images,
  getColor: state => (gruopId, color) => {
    var result = state.images.filter((item) => item.productGroupId == gruopId && item.color == color);
    if (result.length > 0) {
      return result[0].fileName;
    } else {
      return null;
    }
  }
}
