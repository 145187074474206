import axios from "@/axios.js"
import store from "@/store/store";
import Swal from 'sweetalert2';


import {
  dataTransform
} from '@/utils'


// const envBaseURL = process.env.VUE_APP_API;
// const envImgUrl = process.env.VUE_APP_IMAGE_URL;

const state = {
  //  baseUrl: envBaseURL,
  // imageUrl: envImgUrl,
   overlay: false,
  buttonLock: false,
   defaultImage: "/media/0mine/defaultImage.png",
};

const getters = {
  baseUrl: state => state.baseUrl,
  imageUrl: state => state.imageUrl,
  overlay: state => state.overlay,
  buttonLock: state => state.buttonLock,

};

const actions = {


  async loadData({
    commit
  },param) {

    let res = null;
    let list = [];

    let url = param.apiUrl;
    let data = param.params === null || param.params === undefined ? {} :  param.params ;
    let useStore = param.useStore === null || param.useStore === undefined ? {} :  param.useStore ;
    // console.log("9.00000 loadData param >>> ",param);
    console.log("%%%%%%%%%%  LOADDDDDDDDDDDD Daaaataaa>>> ",store.state.auth.apiHeader);

    try {

      res = await axios.post(store.state.apiURL + url, data, store.state.auth.apiHeader);
      // res = await axios.post(store.state.apiURL + params.apiURL, params.params, store.state.auth.apiHeader);
      // res = state.items;

      let item = {list:  res.data.itemList,
        transform:  store.getters[useStore + "/transform" ],
        useStore : useStore
      };

      // console.log("%%%%%%%%%% loadData AFTER TRANSFORM >>> ",item);

      commit("TRANSFORM", item);

      store.commit("sidebar/allSidebarClose");


      //  console.log("%%%%%%%%%% ENDDDDDD >>> ",ss);

    } catch (err) {
       Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถรับข้อมูลได้',
          });
    }


    return list;
  },



  //*** SUBMIT DATA FROM FORM
  async submitData({commit
  }, param) {

    let url = param.apiUrl;
    let data = param.params;
    let form = param.formDetail

    // console.log("COMMON %%%%%%%%%% BEGIN >>> ");


    let submitRes = null;

    try {
      submitRes = await axios.post(
        // await axios.post(
          store.state.apiURL + url ,
          data
          , store.state.auth.apiHeader
        );
      // res = state.items;

      if (form.nextAction === "callasync") {

          let params = {
            apiUrl: form.callApi,
            params : form.callParams,
            useStore : form.callUseStore
            // params: {
            //    params : form.callParams,
            //    useStore : form.callUseStore
            // }



          };
          await store.dispatch(form.callAction,params);
          // redirectTo
          // window.location.href = form.redirectTo;
          // router.push(form.redirectTo)
        }

       console.log("COMMON RESULT >>> ",submitRes);

    } catch (err) {
       Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถบันทึกได้',
       });
      //  store.commit("common/BUTTON_UNLOCK");

    }

    // commit("CLEAR_FORM");

    commit("BUTTON_UNLOCK");



  },

  //*** SUBMIT DATA FROM FORM
  async postData({commit
  }, param) {

    //**************************************** /
    //  param = {
    //    item : item,
    //    detail : this.listDetail.tableAction.deleteAction
    //  }
    //**************************************** /
    let url = param.formDetail.apiUrl;
    let data = param.apiParam;
    let form = param.formDetail

    // console.log("COMMON %%%%%%%%%% BEGIN >>> ");


    let submitRes = null;

    try {
      submitRes = await axios.post(
        // await axios.post(
          store.state.apiURL + url ,
          data
          , store.state.auth.apiHeader
        );
      // res = state.items;

      if (form.nextAction === "callasync") {

          let params = {
            apiUrl: form.callApi,
            params : form.callParams,
            useStore : form.callUseStore
            // params: {
            //    params : form.callParams,
            //    useStore : form.callUseStore
            // }



          };
          await store.dispatch(form.callAction,params);
          // redirectTo
          // window.location.href = form.redirectTo;
          // router.push(form.redirectTo)
        }

       console.log("COMMON RESULT >>> ",submitRes);

    } catch (err) {
       Swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถบันทึกได้',
       });
      //  store.commit("common/BUTTON_UNLOCK");

    }

    // commit("CLEAR_FORM");

    commit("BUTTON_UNLOCK");



  },


   //*** SUBMIT DATA FROM FORM
    async postNeedConfirm({commit
    }, param) {

      //**************************************** /
    //  param = {
    //    item : item,
    //    detail : this.listDetail.tableAction.deleteAction
    //  }
    //**************************************** /
    let url = param.formDetail.apiUrl;
    let data = param.apiParam;
    let form = param.formDetail

    let submitRes = null;

      try {

      Swal.fire({
          icon: 'warning',
          // title: 'ตรวจ',
          showCancelButton: true,
          text: form.confirmText,
          confirmButtonText: form.confirmButtonText,
      })
      .then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.value) {
                submitRes = await axios.post(
                  // await axios.post(
                    store.state.apiURL + url ,
                    data
                    , store.state.auth.apiHeader
                  );
                // res = state.items;

                if (form.nextAction === "callasync") {

                  let params = {
                    apiUrl: form.callApi,
                    params : form.callParams,
                    useStore : form.callUseStore
                  };
                  await store.dispatch(form.callAction,params);
                  // redirectTo
                  // window.location.href = form.redirectTo;
                  // router.push(form.redirectTo)
                }

               console.log("COMMON RESULT >>> ",submitRes);
            }
          })
      ;










      } catch (err) {
         Swal.fire({
              icon: 'warning',
              title: 'ไม่สามารถบันทึกได้',
         });
        //  store.commit("common/BUTTON_UNLOCK");

      }

      // commit("CLEAR_FORM");

      commit("BUTTON_UNLOCK");



    },

};

const mutations = {

  BUTTON_LOCK(state) {
    state.buttonLock = true;
  },

  BUTTON_UNLOCK(state) {
    state.buttonLock = false;
  },

  OVERLAY(state, param) {
    state.overlay = param;
  },
  TRANSFORM(state, item) {
    console.log("@@@@@@@@ TRANSFORM @@@@@@@ ",item);

    let list = item.list;
    let transform = item.transform;
    let items =  dataTransform(list, transform);
    store.commit(item.useStore + "/SET_ITEMS", items);
    },

    TRANSFORM_UNSHIFT(state, item) {

    let list = item.list;
    let transform = item.transform;
    let newlist =  dataTransform(list, transform);

    state.items.unshift(...newlist);

    // console.log("@@@@@@@@@@@@@@@@@@@ state.items @@@@@@@@@@@@ ", state.items);


    },

};



export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
