

export default {
  // SAVE_CHANGE(state) {

  // },
  ADD_ITEM(state, item) {
    state.products.unshift(item)
  },
  ADD_IMAGE(state, item) {

    state.images.unshift(item);


  },
  APPLY_ALL(state, item) {
    state.products.forEach(element => {
      element[item.key] = item.value;
    });
  },
  SET_IMAGE(state, item) {
    state.images = item;


  },

  ADD_SEARCH_PARAM(state, item) {
    Object.assign(state.searchParameters, item);
  },
  CLEAR_SEARCH_PARAM(state) {
    state.searchParameters = {};
  },



  ADD_SELECTED(state, item) {

    var fil = state.selected.filter(x => x.productItemId === item.productItemId);
    if (fil.length < 1) {
      state.selected.push(item);
    } else {
      const ItemIndex = state.selected.findIndex((p) => p.productItemId == item.productItemId)
      state.selected.splice(ItemIndex, 1);
    }
  },

  OPEN_CATALOG_PANEL(state, value){
    state.catalogPanel = value;
  },

  UPDATE_CATALOG(state, list){
    state.catalog = [];
    state.catalog = list;

    console.log("ADD_CATALOG state.catalog >>> ", state.catalog   );
  },

  // REMOVE_CATALOG(state, list){
  //   state.catalog = [];
  //   state.catalog.push(...list);

  //   console.log("ADD_CATALOG state.catalog >>> ", state.catalog   );
  // },

  SELECTED_All(state, list) {
    state.selected = [];
    state.selected.push(...list);

  },

  // SELECTED_All(state, list) {
  //   var indList = [];
  //   state.selected.forEach((ele, index) => {
  //     var chk = list.filter(x => x.productItemId === ele.productItemId);
  //     if (chk.length > 0) {
  //       indList.push(index);
  //     }
  //   });


  //   for (let ind = indList.length - 1; ind >= 0; ind--) {
  //     state.selected.splice(indList[ind], 1);

  //   }

  //   state.selected.push(...list);

  // },

  UNSELECTED_All(state, list) {
    var indList = [];
    state.selected.forEach((ele, index) => {
      var chk = list.filter(x => x.productItemId === ele.productItemId);
      if (chk.length > 0) {
        indList.push(index);
      }
    });

    for (let ind = indList.length - 1; ind >= 0; ind--) {
      state.selected.splice(indList[ind], 1);

    }

  },

  CLEAR_ALL(state) {
    state.selected = [];
    state.selectedNumber = 0;

  },

  // CHECK_SELECTED(state, item) {
  //   var fil = state.selected.filter(x => x.productItemId === item.productItemId);
  //   if (fil.length < 1) {
  //     state.selected.unshift(item);
  //   } else {
  //     const ItemIndex = state.selected.findIndex((p) => p.productItemId == item.productItemId)
  //     state.selected.splice(ItemIndex, 1)
  //   }

  //   // console.log('ITEM ++++++++++++++++++++++++++ ', fil);
  //   // console.log('ITEM ++++++++++++++++++++++++++ ', state.selected);

  // },

  SET_PRODUCTS(state, products) {
    Object.assign(state.products, products)

  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_PRODUCT(state, item) {
    const productIndex = state.products.findIndex((p) => p.id == item.id)
    Object.assign(state.products[productIndex], item)
  },
  REMOVE_IMAGE(state, item) {
    state.images.unshift(item)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.products.findIndex((p) => p.id == itemId)
    state.products.splice(ItemIndex, 1)
  },
  REMOVE_ALL(state) {
    state.products = [];
  },

  SET_CHANGE(state) {
    state.somethingChange = state.somethingChange + 1;
  },

  RESET_CHANGE(state) {
    state.somethingChange = 0;
  },
}
