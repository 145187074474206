

import Vue from 'vue'
import Router from 'vue-router'
// import auth from "@/auth/authService";
import axios from 'axios';
import store from './store/store'
import Swal from 'sweetalert2'

// import firebase from 'firebase/app'
// import 'firebase/auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [

    // {
    //   // =============================================================================
    //   // Catalog Mobile
    //   // =============================================================================
    //   path: "/mreview/:shop_id/:branch_token",
    //   name: "catalog-on-mobile",
    //   component: () =>
    //     import("./layouts/mobile/Main.vue"),
    //   meta: {
    //     rule: "staff",
    //     AuthenNotRequired: 'y'
    //   }
    // },
    // {
    //   // =============================================================================
    //   // Catalog Mobile Cart
    //   // =============================================================================
    //   path: "/mreview/cart",
    //   name: "catalog-on-mobile-cart",
    //   component: () =>
    //     import("./views/pages/backoffice/mobile/MobileCart.vue"),
    //   meta: {
    //     rule: "staff",
    //     AuthenNotRequired: 'y'
    //   }
    // },
    // {
    //   // =============================================================================
    //   // Catalog Mobile Cart Confirm
    //   // =============================================================================
    //   path: "/mreview/cart/confirm",
    //   name: "catalog-on-mobile-cart-confirm",
    //   component: () =>
    //     import("./views/pages/backoffice/mobile/MobileCartConfirm.vue"),
    //   meta: {
    //     rule: "staff",
    //     AuthenNotRequired: 'y'
    //   }
    // },

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/backoffice/rental'
        },

        {
          path: '/dashboard/analytics',
          name: 'dashboard-analytics',
          component: () => import('./views/DashboardAnalytics.vue'),
          meta: {
            rule: 'staff',
          }
        },
        {
          path: '/dashboard/ecommerce',
          name: 'dashboard-ecommerce',
          component: () => import('./views/DashboardECommerce.vue'),
          meta: {
            rule: 'admin'
          }
        },


        // =============================================================================
        // Application Routes BELL : Wintershop backoffice
        // =============================================================================
        {
          path: '/',
          redirect: '/backoffice/rental'
        },
        {
          path: '/backoffice/ex/expandtable',
          name: 'Expand Table',
          component: () => import('./views/wintershopback/ex/expandtable.vue'),

        },

        {
          path: '/backoffice/me',
          name: 'User Settings',
          component: () => import('./views/wintershopback/Components/user-settings/UserSettings.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ผู้ใช้งาน',
                active: true
              },
            ],
            pageTitle: 'ผู้ใช้งาน',
            rule: 'staff'
          },
        },

        {
          path: '/backoffice/shop/settings',
          name: 'Shop Settings',
          component: () => import('./views/wintershopback/Components/shop-settings/ShopSettings.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ตั้งค่าร้านค้า',
                active: true
              },
            ],
            pageTitle: 'ตั้งค่าร้านค้า',
            rule: 'admin'
          },
        },
        {
          path: '/backoffice/rentalnew',
          name: 'rental',
          component: () => import('./views/wintershopback/RentalNew.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'เปิดรายการเช่าชุด',
                active: true
              },
            ],
            pageTitle: 'เปิดรายการเช่าชุด',
            rule: 'staff'
          },
        },

        {
          path: '/backoffice/rental',
          name: 'rental',
          component: () => import('./views/wintershopback/Rental.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'เปิดรายการเช่าชุด',
                active: true
              },
            ],
            pageTitle: 'เปิดรายการเช่าชุด',
            rule: 'staff'
          },
        },


        {
          path: '/backoffice/rental-edit/:orderid',
          name: 'rental edit',
          component: () => import('./views/wintershopback/RentalEdit.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'แก้ไขรายการเช่าชุด',
                active: true
              },
            ],
            pageTitle: 'แก้ไขรายการเช่าชุด',
            rule: 'staff'
          },
        },

        {
          path: '/backoffice/additem/:productgroupid',
          name: 'Add Item',
          component: () => import('./views/wintershopback/AddItem.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'เพิ่มชุดเช่า',
                active: true
              },
            ],
            pageTitle: 'เพิ่มชุดเช่า',
            rule: 'staff'
          },
        },

        {
          path: '/backoffice/updateitem/:productgroupid',
          name: 'Add Item',
          component: () => import('./views/wintershopback/UpdateItem.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'แก้ไขชุดเช่า',
                active: true
              },
            ],
            pageTitle: 'แก้ไขชุดเช่า',
            rule: 'staff'
          },
        },


        {
          path: '/backoffice/add-image',
          name: 'Add Image',
          component: () => import('./views/wintershopback/AddImageComponent.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'เพิ่มชุดเช่า',
                active: true
              },
            ],
            pageTitle: 'เพิ่มชุดเช่า',
            rule: 'staff'
          },
        },


        {
          path: '/backoffice/dropdown',
          name: 'Drop down',
          component: () => import('@/views/wintershopback/Components/dropdown.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'drop down',
                active: true
              },
            ],
            pageTitle: 'drop down',
            rule: 'staff'
          },
        },


        // {
        //   path: '/backoffice/rental-hold/:orderid',
        //   name: 'rental hold',
        //   component: () => import('./views/wintershopback/RentalHold.vue'),
        //   meta: {
        //     breadcrumb: [{
        //         title: 'Home',
        //         url: '/'
        //       },
        //       {
        //         title: 'เก็บสิทธิ์การเช่าชุด',
        //         active: true
        //       },
        //     ],
        //     pageTitle: 'เก็บสิทธิ์การเช่าชุด',
        //     rule: 'staff'
        //   },
        // },

        {
          path: '/backoffice/rental/:orderid',
          name: 'rental',
          component: () => import('./views/wintershopback/Rental.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'เปิดรายการเช่าชุด',
                active: true
              },
            ],
            pageTitle: 'เปิดรายการเช่าชุด',
            rule: 'staff'
          },
        },
        {
          path: '/backoffice/datalist-thumb',
          name: 'datathumb',
          component: () => import('./views/wintershopback/DataListThumbView.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายการสินค้า',
                active: true
              },
            ],
            pageTitle: 'รายการสินค้า',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/config/category',
          name: 'Configuration category',
          component: () => import('./views/wintershopback/Configurations/Category.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ประเภทสินค้า',
                active: true
              },
            ],
            pageTitle: 'ประเภทสินค้า',
            rule: 'admin'
          },
        },
        {
          path: '/backoffice/customer',
          name: 'Customer',
          component: () => import('./views/wintershopback/Customer.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ข้อมูลลูกค้า',
                active: true
              },
            ],
            pageTitle: 'ข้อมูลลูกค้า',
            rule: 'staff'
          },
        },

        {
          // WINTERSHOP
          path: '/backoffice/user-management',
          name: 'จัดการข้อมูลผู้ใช้งาน',
          component: () => import('./views/wintershopback/User.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'จัดการข้อมูลผู้ใช้งาน',
                active: true
              },
            ],
            pageTitle: 'จัดการข้อมูลผู้ใช้งาน',
            rule: 'admin'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/config/texture',
          name: 'Configuration texture',
          component: () => import('./views/wintershopback/Configurations/Texture.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ชนิด',
                active: true
              },
            ],
            pageTitle: 'ชนิด',
            rule: 'admin'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/config/size',
          name: 'Configuration size',
          component: () => import('./views/wintershopback/Configurations/Size.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ขนาด',
                active: true
              },
            ],
            pageTitle: 'ขนาด',
            rule: 'admin'
          },
        },

        {
          // ALEX 2021-07-09
          path: '/backoffice/movies',
          name: 'สินค้ารอดำเนินการ',
          component: () => import('./views/wintershopback/Movie.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'สินค้ารอดำเนินการ',
                active: true
              },
            ],
            pageTitle: 'สินค้ารอดำเนินการ',
            rule: 'staff'
          },
        },
 {
          // Test ALEX 2021-07-09
          path: '/backoffice/game',
          name: 'เพิ่ม PRODUCT',
          component: () => import('./views/wintershopback/Composition/Game/Game.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'เพิ่มproduct/ซ่อม',
                active: true
              },
            ],
            pageTitle: 'สินค้ารอดำเนินการ',
            rule: 'staff'
          },
        },
        {
          // Test ALEX 2021-07-09
          path: '/backoffice/add-product',
          name: 'เพิ่ม PRODUCT',
          component: () => import('./views/wintershopback/Composition/Products/AddProduct.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'เพิ่มproduct/ซ่อม',
                active: true
              },
            ],
            pageTitle: 'สินค้ารอดำเนินการ',
            rule: 'staff'
          },
        },

        {
          // ALEX 2021-07-09
          path: '/backoffice/cleanandfix-items',
          name: 'สินค้ารอดำเนินการ',
          component: () => import('./views/wintershopback/CleanFixItems.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'สินค้ารอดำเนินการ',
                active: true
              },
            ],
            pageTitle: 'สินค้ารอดำเนินการ',
            rule: 'staff',
            packageRequired: 'm'
          },
        },
        {
          // ALEX 2021-07-09
          path: '/backoffice/cleanandfix-order',
          name: 'ออเดอร์กำลังดำเนินการ',
          component: () => import('./views/wintershopback/CleanFixOrder.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ออเดอร์กำลังดำเนินการ',
                active: true
              },
            ],
            pageTitle: 'ออเดอร์กำลังดำเนินการ',
            rule: 'staff',
            packageRequired: 'm'
          },
        },
        {
          // ALEX 2021-08-05
          path: '/backoffice/cleanandfix-shop',
          name: 'ผู้ติดต่อ',
          component: () => import('./views/wintershopback/CleanFixShop.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ผู้ติดต่อ (Vendor/Supplier)',
                active: true
              },
            ],
            pageTitle: 'ผู้ติดต่อ',
            rule: 'staff',
            packageRequired: 'm'
          },
        },
        {
          // ALEX 2021-07-09
          path: '/backoffice/cleanandfix-orders',
          name: 'บิลส่งซัก/ซ่อม',
          component: () => import('./views/wintershopback/CleanFixOrders.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'บิลส่งซัก/ซ่อม',
                active: true
              },
            ],
            pageTitle: 'บิลส่งซัก/ซ่อม',
            rule: 'staff',
            packageRequired: 'm'
          },
        },


        {
          // WINTERSHOP
          path: '/backoffice/size-by-order',
          name: 'Configuration size by order',
          component: () => import('./views/wintershopback/Configurations/SizeByOrder.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ขนาด',
                active: true
              },
            ],
            pageTitle: 'ขนาด',
            rule: 'admin'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/config/monetary',
          name: 'Configuration monetary',
          component: () => import('./views/wintershopback/Configurations/Monetary.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'หมวดหมู่ค่าใช้จ่าย',
                active: true
              },
            ],
            pageTitle: 'หมวดหมู่ค่าใช้จ่าย',
            rule: 'admin'
          },
        },

        {
          // WINTERSHOP
          path: '/backoffice/config/',
          name: 'Configuration Color',
          component: () => import('./views/wintershopback/Configurations/Color.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รหัสสีชุดเช่า',
                active: true
              },
            ],
            pageTitle: 'รหัสสีชุดเช่า',
            rule: 'admin'
          },
        },

        {
          // WINTERSHOP
          path: '/backoffice/config/drag',
          name: 'DragAndDropSimple',
          component: () => import('./views/components/extra-components/drag-and-drop/DragAndDrop.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ลากวาง',
                active: true
              },
            ],
            pageTitle: 'ลากวาง',
            rule: 'admin'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/config/web-category',
          name: 'Configuration Web Category',
          component: () => import('./views/wintershopback/Configurations/WebCategory.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'หมวดหมู่หน้าเว็บ',
                active: true
              },
            ],
            pageTitle: 'หมวดหมู่หน้าเว็บ',
            rule: 'admin'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/config/color',
          name: 'Configuration Color',
          component: () => import('./views/wintershopback/Configurations/Color.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รหัสสีชุดเช่า',
                active: true
              },
            ],
            pageTitle: 'รหัสสีชุดเช่า',
            rule: 'admin'
          },
        },

        {
          // WINTERSHOP
          path: '/backoffice/config/duration',
          name: 'Configuration duration',
          component: () => import('./views/wintershopback/Configurations/Duration.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'การแบ่งราคาสินค้า',
                active: true
              },
            ],
            pageTitle: 'การแบ่งราคาสินค้า',
            rule: 'admin'
          },
        },



        {
          // WINTERSHOP
          path: '/backoffice/config/color',
          name: 'Configuration Color',
          component: () => import('./views/wintershopback/Configurations/Color.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รหัสสีชุดเช่า',
                active: true
              },
            ],
            pageTitle: 'รหัสสีชุดเช่า',
            rule: 'admin'
          },
        },


        {
          // WINTERSHOP
          path: '/backoffice/shop-info',
          name: 'Shop Information',
          component: () => import('./views/wintershopback/ShopInfo.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายละเอียดข้อมูลหน้าร้าน',
                active: true
              },
            ],
            pageTitle: 'รายละเอียดข้อมูลหน้าร้าน',
            rule: 'admin'
          },
        },
        // {
        //   // WINTERSHOP
        //   path: '/backoffice/promotion',
        //   name: 'Promotion',
        //   component: () => import('./views/wintershopback/Promotion.vue'),
        //   meta: {
        //     breadcrumb: [{
        //         title: 'Home',
        //         url: '/'
        //       },
        //       {
        //         title: 'รายละเอียดโปรโมชั่น',
        //         active: true
        //       },
        //     ],
        //     pageTitle: 'รายละเอียดโปรโมชั่น',
        //     rule: 'admin'
        //   },
        // },
        {
          // WINTERSHOP
          path: '/backoffice/return-detail/:orderid',
          name: 'Return Detail',
          component: () => import('./views/wintershopback/ReturnDetail.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายละเอียดการรอรับชุด',
                active: true
              },
            ],
            pageTitle: 'รายละเอียดการรอคืนชุด',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/return-detail-old/:orderid',
          name: 'Return Detail',
          component: () => import('./views/wintershopback/ReturnDetail-old.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายละเอียดการรอรับชุด',
                active: true
              },
            ],
            pageTitle: 'รายละเอียดการรอคืนชุด',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/calendar',
          name: 'Calendar',
          component: () => import('./views/wintershopback/Calendar.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ปฏิทิน',
                active: true
              },
            ],
            pageTitle: 'ปฏิทิน',
            rule: 'staff'
          },
        },



        {
          // WINTERSHOP
          path: '/backoffice/todo',
          name: 'Preparing',
          component: () => import('./views/wintershopback/Todo.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายการงานที่ต้องทำ',
                active: true
              },
            ],
            pageTitle: 'รายการงานที่ต้องทำ',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/step',
          name: 'Preparing',
          component: () => import('./views/wintershopback/TabIcon.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายการงานที่ต้องทำ',
                active: true
              },
            ],
            pageTitle: 'รายการงานที่ต้องทำ',
            rule: 'staff'
          },
        },

        // {
        //   // WINTERSHOP
        //   path: '/backoffice/borrow',
        //   name: 'Borrow',
        //   component: () => import('./views/wintershopback/Borrow.vue'),
        //   meta: {
        //     breadcrumb: [{
        //         title: 'Home',
        //         url: '/'
        //       },
        //       {
        //         title: 'ยืมชุด',
        //         active: true
        //       },
        //     ],
        //     pageTitle: 'ยืมชุด',
        //     rule: 'staff'
        //   },
        // },


        {
          // WINTERSHOP
          path: '/backoffice/testcom',
          name: 'testcom',
          component: () => import('./views/wintershopback/Components/testcom.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'testcom',
                active: true
              },
            ],
            pageTitle: 'testcom',
            rule: 'staff'
          },
        },


        {
          // WINTERSHOP
          path: '/backoffice/select1',
          name: 'select1',
          component: () => import('./views/wintershopback/Components/Selection1.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'select1',
                active: true
              },
            ],
            pageTitle: 'select1',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/todo-order-detail/:orderid',
          name: 'Todo Order Detail',
          component: () => import('./views/wintershopback/TodoOrderDetail.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายการที่ต้องทำ',
                active: true
              },
            ],
            pageTitle: 'รายการที่ต้องทำ',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/tododetail/:orderid',
          name: 'Preparing',
          component: () => import('./views/wintershopback/Todo.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายการทำต้องทำ',
                active: true
              },
            ],
            pageTitle: 'รายการทำต้องทำ',
            rule: 'staff'
          },
        },


        {
          // WINTERSHOP
          path: '/backoffice/already-return-detail/:orderno',
          name: 'Return Detail',
          component: () => import('./views/wintershopback/AlreadyReturnDetail.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายละเอียดการรอรับชุด',
                active: true
              },
            ],
            pageTitle: 'รายละเอียดการรอคืนชุด',
            rule: 'staff'
          },
        },

        {
          // WINTERSHOP
          path: '/backoffice/alreadyreturn-old',
          name: 'Return Detail',
          component: () => import('./views/wintershopback/AlreadyReturn-old.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายละเอียดการรอรับชุด',
                active: true
              },
            ],
            pageTitle: 'รายละเอียดการรอคืนชุด',
            rule: 'staff'
          },
        },

        {
          // WINTERSHOP
          path: '/backoffice/blog',
          name: 'Blog',
          component: () => import('./views/wintershopback/Blog.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'บล็อก',
                active: true
              },
            ],
            pageTitle: 'บล็อก',
            rule: 'admin'
          },
        },

        {
          // WINTERSHOP
          path: '/backoffice/blog-detail/:blogid',
          name: 'Blog',
          component: () => import('./views/wintershopback/BlogDetail.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'บล็อก',
                active: true
              },
            ],
            pageTitle: 'บล็อก',
            rule: 'admin'
          },
        },

        {
          // WINTERSHOP
          path: '/backoffice/return-detail/:orderno',
          name: 'Return Detail',
          component: () => import('./views/wintershopback/ReturnDetail.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายละเอียดการรอรับชุด',
                active: true
              },
            ],
            pageTitle: 'รายละเอียดการรอคืนชุด',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/order-detail/:orderid',
          name: 'Order Detail',
          component: () => import('./views/wintershopback/OrderDetail.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายละเอียดการเช่าชุด',
                active: true
              },
            ],
            pageTitle: 'รายละเอียดการเช่าชุด',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/web-order-detail/:orderid',
          name: 'Web Order Detail',
          component: () => import('./views/wintershopback/WebOrderDetail.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายละเอียดการเช่าชุดจากหน้าเว็บ',
                active: true
              },
            ],
            pageTitle: 'รายละเอียดการเช่าชุดจากหน้าเว็บ',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/accounting',
          name: 'Accounting',
          component: () => import('./views/wintershopback/Accounting.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายรับ-รายจ่าย',
                active: true
              },
            ],
            pageTitle: 'รายรับ-รายจ่าย',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/moneyback',
          name: 'Moneyback',
          component: () => import('./views/wintershopback/Moneyback.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รายการเงินรับ-คืนเงิน',
                active: true
              },
            ],
            pageTitle: 'รายการเงินรับ-คืนเงิน',
            rule: 'owner'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/create-item/:productid',
          name: 'CreateItem',
          component: () => import('./views/wintershopback/CreateItem.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'เพิ่มสินค้า',
                active: true
              },
            ],
            pageTitle: 'เพิ่มสินค้า',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/assign-to-branch/:productid',
          name: 'CreateItem',
          component: () => import('./views/wintershopback/AssignToBranch.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'จัดสินค้าลงสาขา',
                active: true
              },
            ],
            pageTitle: 'จัดสินค้าลงสาขา',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/image-upload',
          name: 'Image Uload',
          component: () => import('./views/wintershopback/Components/ImageUpload.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'upload image',
                active: true
              },
            ],
            pageTitle: 'upload image',
            rule: 'staff'
          },
        },
        // {
        //   // WINTERSHOP
        //   path: '/backoffice/create-item/:productid',
        //   name: 'Stock',
        //   component: () => import('./views/wintershopback/CreateItem.vue'),
        //   meta: {
        //     breadcrumb: [{
        //         title: 'Home',
        //         url: '/'
        //       },
        //       {
        //         title: 'Stock',
        //         active: true
        //       },
        //     ],
        //     pageTitle: 'Stock',
        //     rule: 'staff'
        //   },
        // },

        {
          // WINTERSHOP
          path: '/backoffice/alreadyreturn',
          name: 'Already return',
          component: () => import('./views/wintershopback/AlreadyReturn.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'คืนชุดแล้ว',
                active: true
              },
            ],
            pageTitle: 'คืนชุดแล้ว',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/stock',
          name: 'Stock',
          component: () => import('./views/wintershopback/Stock.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'สต็อคสินค้า',
                active: true
              },
            ],
            pageTitle: 'สต็อคสินค้า',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/other-stock',
          name: 'stockother',
          component: () => import('./views/wintershopback/StockOther.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'สต็อคสาขาอื่น',
                active: true
              },
            ],
            pageTitle: 'สต็อคสาขาอื่น',
            rule: 'onlyStaff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/stock-list',
          name: 'Stock',
          component: () => import('./views/wintershopback/StockList.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'สต็อคสินค้า',
                active: true
              },
            ],
            pageTitle: 'สต็อคสินค้า',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/add-group/:productid',
          name: 'Add Group',
          component: () => import('./views/wintershopback/WebAddGroup.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'เพิ่มสินค้าเข้ากลุ่ม',
                active: true
              },
            ],
            pageTitle: 'เพิ่มสินค้าเข้ากลุ่ม',
            rule: 'admin'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/product-review/:productgroupid',
          name: 'Review Product',
          component: () => import('./views/wintershopback/ProductReview.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รีวิวสินค้า',
                active: true
              },
            ],
            pageTitle: 'รีวิวสินค้า',
            rule: 'admin'
          },
        },
        // {
        //   // WINTERSHOP
        //   path: '/backoffice/other-stock',
        //   name: 'Other Stock',
        //   component: () => import('./views/wintershopback/OtherStock.vue'),
        //   meta: {
        //     breadcrumb: [{
        //         title: 'Home',
        //         url: '/'
        //       },
        //       {
        //         title: 'สต็อคของสาขาอื่น',
        //         active: true
        //       },
        //     ],
        //     pageTitle: 'สต็อคของสาขาอื่น',
        //     rule: 'onlyStaff'
        //   },
        // },
        {
          // WINTERSHOP
          path: '/backoffice/add-group',
          name: 'Other Stock',
          component: () => import('./views/wintershopback/WebAddGroup.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'เพิ่มสินค้าเข้ากลุ่ม',
                active: true
              },
            ],
            pageTitle: 'เพิ่มสินค้าเข้ากลุ่ม',
            rule: 'admin'
          },
        },

        {
          // WINTERSHOP
          path: '/backoffice/mylogin',
          name: 'mylogin',
          component: () => import('./views/wintershopback/mylogin.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'My Login',
                active: true
              },
            ],
            pageTitle: 'My Login',
            // rule: 'staff'
          },
        },
        // ALEX APP
        //  {
        //    path: '/pages/abb-receipt/:orderid',
        //    name: 'abb-receipt',
        //    component: () => import('@/views/pages/AbbReceipt.vue'),
        //    meta: {
        //      breadcrumb: [{
        //          title: 'Home',
        //          url: '/'
        //        },
        //        {
        //          title: 'Pages'
        //        },
        //        {
        //          title: 'ใบเสร็จรับเงินอย่างย่อ',
        //          active: true
        //        },
        //      ],
        //      pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
        //      rule: 'staff'
        //    },
        // },
        //*** 2022-06-05 : BEGIN For ABB RECEIPT FOR SALE
        // ALEX APP
         {
           path: '/pages/abb-receipt-so/1/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceiptSo-1-200.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
         },
         //*** 2022-06-05 : BEGIN For ABB RECEIPT FOR SALE
         // ALEX APP
         {
           path: '/pages/abb-receipt-so/2/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceiptSo-2-220.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
         },

         //*** 2022-06-05 : BEGIN For ABB RECEIPT FOR SALE
         // ALEX APP
         {
           path: '/pages/abb-receipt-so/3/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceiptSo-3-260.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
         },

         //*** 2022-06-05 : BEGIN For ABB RECEIPT FOR SALE
         // ALEX APP
         {
           path: '/pages/abb-receipt-so/4/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceiptSo-4-300.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
        },

         //*** 2022-06-05 : BEGIN For ABB RECEIPT FOR SALE
         // ALEX APP
         {
           path: '/pages/abb-receipt-so/5/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceiptSo-5-340.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
        },


        //*** 2022-06-05 : BEGIN For ABB RECEIPT FOR SALE
         // ALEX APP
         {
           path: '/pages/abb-receipt-so/6/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceiptSo-6-380.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
        },

        //*** 2022-06-05 : BEGIN For ABB RECEIPT FOR SALE
         // ALEX APP
         {
           path: '/pages/abb-receipt-so/7/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceiptSo-7-420.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
        },



        //*** 2021-03-09 : BEGIN For ABB RECEIPT
        // ALEX APP
         {
           path: '/pages/abb-receipt/1/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceipt-1-330.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
         },

        // ALEX APP
         {
           path: '/pages/abb-receipt/2/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceipt-2-350.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
        },
         // ALEX APP
         {
           path: '/pages/abb-receipt/3/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceipt-3-380.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
         },
          // ALEX APP
          {
           path: '/pages/abb-receipt/4/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceipt-4-480.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
        },

        // ALEX APP
          {
           path: '/pages/abb-receipt/5/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceipt-5-580.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
        },

        // ALEX APP
          {
           path: '/pages/abb-receipt/6/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceipt-6-680.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
        },

        // ALEX APP
          {
           path: '/pages/abb-receipt/7/:cat/:orderid',
           name: 'abb-receipt',
           component: () => import('@/views/pages/AbbReceipt-7-780.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'ใบเสร็จรับเงินอย่างย่อ',
                 active: true
               },
             ],
             pageTitle: 'ใบเสร็จรับเงินอย่างย่อ',
             rule: 'staff'
           },
        },

        //*** 2021-03-09 : END For ABB RECEIPT
        // ALEX APP
         {
           path: '/pages/catalog-page',
           name: 'catalog-invoice',
           component: () => import('@/views/pages/CatalogPage.vue'),
           meta: {
             breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Pages'
               },
               {
                 title: 'Catalog',
                 active: true
               },
             ],
             pageTitle: 'Catalog',
             rule: 'staff'
           },
         },



        {
          // WINTERSHOP
          path: '/backoffice/return-old',
          name: 'Waiting for Returning',
          component: () => import('./views/wintershopback/Return-old.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รอคืนชุด',
                active: true
              },
            ],
            pageTitle: 'รอคืนชุด',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/return',
          name: 'Waiting for Returning',
          component: () => import('./views/wintershopback/Return.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รอคืนชุด',
                active: true
              },
            ],
            pageTitle: 'รอคืนชุด',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/saved',
          name: 'บันทึกรายการเช่า',
          component: () => import('./views/wintershopback/Saved.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'บันทึกรายการเช่า',
                active: true
              },
            ],
            pageTitle: 'บันทึกรายการเช่า',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/web-submit',
          name: 'ออเดอร์จากหน้าเว็บ',
          component: () => import('./views/wintershopback/WebSubmit.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ออเดอร์จากหน้าเว็บ',
                active: true
              },
            ],
            pageTitle: 'ออเดอร์จากหน้าเว็บ',
            rule: 'staff'
          },
        },
        {
          // WINTERSHOP
          path: '/backoffice/pickup',
          name: 'Waiting for Picking up',
          component: () => import('./views/wintershopback/Pickup.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'รอรับชุด',
                active: true
              },
            ],
            pageTitle: 'รอรับชุด',
            rule: 'staff'
          },
        },

        {
          path: '/backoffice/statistics',
          name: 'extra-component-charts-chartjs',
          component: () => import('@/views/wintershopback/Components/chartjs/Chartjs.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },

              {
                title: 'สถิตของร้าน',
                active: true
              },
            ],
            pageTitle: 'ข้อมูลสถิตของร้าน',
            rule: 'owner'
          },
        },

        {
          path: '/pages/invoice',
          name: 'page-invoice',
          component: () => import('@/views/pages/Invoice.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'Pages'
              },
              {
                title: 'Invoice',
                active: true
              },
            ],
            pageTitle: 'Invoice',
            rule: 'staff'
          },
        },



        // =============================================================================
        // CHARTS & MAPS
        // =============================================================================
        // {
        //   path: '/charts-and-maps/charts/apex-charts',
        //   name: 'extra-component-charts-apex-charts',
        //   component: () => import('@/views/charts-and-maps/charts/apex-charts/ApexCharts.vue'),
        //   meta: {
        //     breadcrumb: [{
        //         title: 'Home',
        //         url: '/'
        //       },
        //       {
        //         title: 'Charts & Maps'
        //       },
        //       {
        //         title: 'Apex Charts',
        //         active: true
        //       },
        //     ],
        //     pageTitle: 'Apex Charts',
        //     rule: 'staff'
        //   },
        // },
        // {
        //   path: '/charts-and-maps/charts/chartjs',
        //   name: 'extra-component-charts-chartjs',
        //   component: () => import('@/views/charts-and-maps/charts/chartjs/Chartjs.vue'),
        //   meta: {
        //     breadcrumb: [{
        //         title: 'Home',
        //         url: '/'
        //       },
        //       {
        //         title: 'Charts & Maps'
        //       },
        //       {
        //         title: 'chartjs',
        //         active: true
        //       },
        //     ],
        //     pageTitle: 'chartjs',
        //     rule: 'staff'
        //   },
        // },
        // {
        //   path: '/charts-and-maps/charts/echarts',
        //   name: 'extra-component-charts-echarts',
        //   component: () => import('@/views/charts-and-maps/charts/echarts/Echarts.vue'),
        //   meta: {
        //     breadcrumb: [{
        //         title: 'Home',
        //         url: '/'
        //       },
        //       {
        //         title: 'Charts & Maps'
        //       },
        //       {
        //         title: 'echarts',
        //         active: true
        //       },
        //     ],
        //     pageTitle: 'echarts',
        //     rule: 'staff'
        //   },
        // },
        // {
        //   path: '/charts-and-maps/maps/google-map',
        //   name: 'extra-component-maps-google-map',
        //   component: () => import('@/views/charts-and-maps/maps/google-map/GoogleMap.vue'),
        //   meta: {
        //     breadcrumb: [{
        //         title: 'Home',
        //         url: '/'
        //       },
        //       {
        //         title: 'Charts & Maps'
        //       },
        //       {
        //         title: 'Google Map',
        //         active: true
        //       },
        //     ],
        //     pageTitle: 'Google Map',
        //     rule: 'staff'
        //   },
        // },



        // =============================================================================
        // Application Routes Pao : backoffice
        // =============================================================================
        {
          path: "/backoffice/online-billing",
          name: "online-billing",
          component: () =>
            import(
              "@/views/pages/backoffice/online-billing/OnlineBillingProductCatalog.vue"
            ),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Online Billing",
                active: true
              }
            ],
            pageTitle: "Online Billing",
            rule: "staff"
          }
        },
        {
          path: "/backoffice/sale-order",
          name: "sale-order",
          component: () =>
            import(
              "@/views/pages/backoffice/sale-order/SaleOrderProductCatalog.vue"
            ),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "ออกบิลขาย",
                active: true
              }
            ],
            pageTitle: "ออกบิลขาย",
            rule: "staff",
            packageRequired: 'm'
          }
        },

        {
          path: "/backoffice/list-sale-order",
          name: "sale-order",
          component: () =>
            import("@/views/pages/backoffice/sale-order/ListSaleOrder.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "รายการขาย",
                active: true
              }
            ],
            pageTitle: "รายการขาย",
            rule: "staff",
            packageRequired: 'm'
          }
        },
        // {
        //   path: "/backoffice/sale-order-detail/:orderId",
        //   name: "sale-order-detail",
        //   component: () =>
        //     import(
        //       "@/views/pages/backoffice/sale-order/SaleOrderDetail.vue"
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       {
        //         title: "Home",
        //         url: "/"
        //       },
        //       {
        //         title: "รายการขาย",
        //         active: true
        //       }
        //     ],
        //     pageTitle: "รายการขาย",
        //     rule: "staff",
        //     packageRequired: 'm'
        //   }
        // },
        {
          path: "/backoffice/customer-rental-history/:customerId",
          name: "customer-rental-history",
          component: () =>
            import("@/views/wintershopback/Components/CustomerRentalHistory.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "ประวัติการเช่า/ซื้อ",
                active: true
              }
            ],
            pageTitle: "ข้อมูลลูกค้า",
            rule: "staff"
          }
        },
        {
          path: "/backoffice/mobile-order",
          name: "mobile-order",
          component: () =>
            import("@/views/pages/backoffice/mobile/MobileOrder.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "ออเดอร์จากลูกค้าหน้าร้าน",
                active: true
              }
            ],
            pageTitle: "ออเดอร์จากลูกค้าหน้าร้าน",
            rule: "staff"
          }
        },
      ]
    },


    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================

        // =============================================================================
        // AlexRentalApp FULL PAGES
        // =============================================================================


        // AlexRentalApp
        {
          path: '/pages/rec/:orderid/:watermark',
          name: 'rec',
          component: () => import('@/views/pages/rec.vue'),
          meta: {
            rule: 'staff'
          }
        },

        // AlexRentalApp
        // Wnf Order
        {
          path: '/pages/wnf-order/:wnforderid',
          name: 'wnforder',
          component: () => import('@/views/pages/WnfOrder.vue'),
          meta: {
            rule: 'staff'
          }
        },

        {
          // ALEX
          path: '/pages/calendar-dayspan',
          name: 'CalendarDaySpan',
          component: () => import('./views/wintershopback/CalendarDaySpan.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'ปฏิทิน',
                active: true
              },
            ],
            pageTitle: 'ปฏิทิน',
            rule: 'staff'
          },
        },


        // AlexRentalApp
        {
          path: '/pages/rec2/:orderid/:watermark',
          name: 'rec2',
          component: () => import('@/views/pages/rec2.vue'),
          meta: {
            rule: 'staff'
          }
        },

        {
          // AlexRentalApp
          path: '/pages/rec-page/:orderid',
          name: 'RecPage',
          component: () => import('./views/pages/RecPage.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'พิมพ์ใบเช็คของ',
                active: true
              },
            ],
            pageTitle: 'พิมพ์ใบเช็คของ',
            rule: 'staff'
          },
        },


        {
          // AlexRentalApp
          path: '/pages/postal-sticker/:orderid',
          name: 'RecPage',
          component: () => import('./views/pages/PostalSticker.vue'),
          meta: {
            breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'พิมพ์ใบส่งของ',
                active: true
              },
            ],
            pageTitle: 'พิมพ์ใบส่งของ',
            rule: 'staff'
          },
        },

        // AlexRentalApp
        // {
        //   path: '/pages/sticker/:orderid',
        //   name: 'sticker',
        //   component: () => import('@/views/pages/Sticker.vue'),
        //   meta: {
        //     rule: 'staff'
        //   }
        // },
        // =============================================================================
        // /AlexRentalApp FULL PAGES
        // =============================================================================

        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'staff'
          }
        },
        // {
        //   path: '/pages/login-v1',
        //   name: 'page-login',
        //   component: () => import('@/views/pages/login/Loginv1.vue'),
        //   meta: {
        //     rule: 'staff'
        //   }
        // },

        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/comingsoon',
          name: 'page-coming-soon',
          component: () => import('@/views/pages/ComingSoon.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'staff'
          }
        },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

// router.beforeEach((to, from, next) => {
router.beforeEach(async (to, from, next) => {
  // firebase.auth().onAuthStateChanged(() => {
  // console.log("Start beforeEach >>>> ",to.fullPath)


  // REPLACE BY BELL
  var tk = localStorage.getItem('token');
  var un = localStorage.getItem('username');


  var authenticated = false;

  // console.log("TOKEN >>>> ", tk);

  if (tk !== null && tk !== undefined && tk !== "") {
    var theToken = JSON.parse(tk);
    var username = JSON.parse(un);


    var
      config = {
        headers: {
          'Authorization': "bearer " + theToken
        }
      };

    // console.log("theToken", theToken);

    var resp;
    try {
      resp = await axios.post(store.state.apiURL + "/api/user/checkAuthen", {
        username: username,
        token: theToken
      }, config);
    } catch (err) {
      resp = null;
      // console.log("BEFORE ROOT >>>> /api/user/checkAuthen resp >>> ", resp);
    }
    // console.log("BEFORE ROOT >>>> /api/user/checkAuthen resp >>> ", resp);

    //  console.log("BEFORE ROOT >>>> /api/user/checkAuthen RESULT ", resp.data);

    if (resp !== null && resp !== undefined) {

      if (resp.status === 200 && resp.data === true) {
        //  console.log("BEFORE ROOT >>>> STATUS 200");

        authenticated = true;
        var resauth = await store.dispatch('auth/getAuth');
        if (!resauth) {
          authenticated = false;
          // console.log("resauth ");
        }


      } else {
        authenticated = false;
      }

      // console.log("BEFORE ROOT >>>> DATA IS OK");

    } else {
      authenticated = false;
      // console.log("BEFORE ROOT >>>>STATUS NOT 200");
    }


  } else {
    authenticated = false;
  }

  //  console.log("BEFORE ROOT >>>> authenticated", authenticated);
  //  console.log("to.path", to.path);
  // login แล้ว หรือ จะไป page login

  var userPackage = store.state.auth.currentPackage;
  var tmpNeedPackage = to.meta.packageRequired;
  var needPackage = "s"
  if (tmpNeedPackage !== null && tmpNeedPackage !== undefined)
  {
    needPackage = tmpNeedPackage;
  }



  var packageLevel = ["s","m","l"];

  var userPackageNum = packageLevel.indexOf(userPackage);
  if (userPackageNum < 0 || userPackageNum > packageLevel.length - 1) userPackageNum = 0;


  var needPackageNum = packageLevel.indexOf(needPackage);
   if (needPackageNum < 0 || needPackageNum > packageLevel.length - 1) needPackageNum = 0;


  var description = "การเข้าใช้งานจำเป็นต้อง upgrade package เป็นไซส์ " + needPackage.toUpperCase();

  if (userPackageNum < needPackageNum)
  {

    // console.log("FROM is ", from);
    // Redirect if user input URL directly!!!
    Swal.fire({
      icon: 'warning',
      title: 'ไม่สามารถเข้าใช้งานได้',
      text: description,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "เข้าใจแล้ว"
    }).then(result => {
      if (result.value) {
          if (from.fullPath === "/")
        {
          window.location.href = "/";
        }
      }

    });
    return;
  }

  // if(packageRequired)
  if(to.meta.AuthenNotRequired === 'y')
  {
    console.log("to.meta.AuthenNotRequired >>> ", to.meta.AuthenNotRequired);
    authenticated = true;
    // next();
  }


  if (!authenticated && to.path !== '/pages/login') {
    //  console.log(" NOT AUTHEN YET ");
    next({
      path: '/pages/login',
      query: {
        to: to.path
      }
    });
    return;
  } else if (!authenticated && to.path === '/pages/login') {
    // console.log("to.path === '/pages/login'>>>  AUTHENTICATED !!! to.fullPath === to.fullPath === '", to.fullPath, "' ");
    next();
    return;
  } else {

    //  console.log(" AUTHENTICATED !!! to.fullPath === '", to.fullPath, "' ");
    next();
  }



});

export default router
