// import { defaultDirection } from '../constants/config'


export const convertToArray = (list, prop) => {
  var newlist = [];

  for (var i = 0; i < list.length; i++) {
    newlist.push(list[i][prop]);
  }

  return newlist;
}



export const automapper = (list, keys) => {
  var newlist = [];
  for (let i = 0; i < list.length; i++) {
    var item = {};
    for (let j = 0; j < keys.length; j++) {
      if (list[0].hasOwnProperty(keys[j])) {
        item[keys[j]] = list[i][keys[j]];
      }

    }
    const it = Object.assign({}, item);
    newlist.push(it);
  }

  return newlist;

}
export const formatDate = function (date) {
  var d = new Date(date),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();

if (month.length < 2) month = '0' + month;
if (day.length < 2) day = '0' + day;

return [year, month, day].join('-');
};

export const dataTransform = function(list, transform){
  // let list = item.list;
  // let transform = item.transform;

    if(list === null || list === undefined){
        return [];
      }

      list.forEach( item => {



            for (let index = 0; index < transform.length; index++) {

              const ele = transform[index];

              //*************************************************************
              //***********************  type: condition ********************
              //*************************************************************

              if (ele["type"] === "condition")
              {
                    let mappingArr = ele["mapping"];

                    const mappingIndex /* [1] */ =  mappingArr.findIndex(object => {
                          return object.from /* [3] */ === item[ele["keyCondition"]] /* [4] */ ; // item[ele["keyCondition"]] เป็นค่าที่จะใช้ map
                    });

                    if (mappingIndex >= 0)
                    {
                      item[ele["keyResult"]] = mappingArr[mappingIndex]["to"];
                    } else {
                      item[ele["keyResult"]] =  ele["defaultTo"];
                    }
              }

              //*************************************************************
              //***********************  type: link ********************
              //*************************************************************

              else if (ele["type"] === "link") {
                item[ele["keyResult"]] =  (item[ele["useField"]] === null || item[ele["useField"]] === undefined || item[ele["useField"]] === "") ? /* เช็คก่อนถ้าเป็น null ใช้ default link */
                                                                              ele["defaultTo"] /* default link */
                                                                            : ele["mapUrl"] + ele["mapText"] + item[ele["useField"]] /* link + "/" + id */;

              }


              //*************************************************************
              //***********************  type: concat ********************
              //*************************************************************

              else if (ele["type"] === "concat") {

                item[ele["useField1"]] = (item[ele["useField1"]] === null || item[ele["useField1"]] === undefined) ? "" : item[ele["useField1"]].toString();
                item[ele["useField2"]] = (item[ele["useField2"]] === null || item[ele["useField2"]] === undefined) ? "" : item[ele["useField2"]].toString();

                ele["mapTextPrefix"] = (ele["mapTextPrefix"] === null || ele["mapTextPrefix"] === undefined) ? "" : ele["mapTextPrefix"];
                ele["mapTextBetween"] = (ele["mapTextBetween"] === null || ele["mapTextBetween"] === undefined) ? "" : ele["mapTextBetween"];
                ele["mapTextSuffix"] = (ele["mapTextSuffix"] === null || ele["mapTextSuffix"] === undefined) ? "" : ele["mapTextSuffix"];



                item[ele["keyResult"]] =
                    ele["mapTextPrefix"]
                  + item[ele["useField1"]]
                  + ele["mapTextBetween"]
                  + item[ele["useField2"]]
                  + ele["mapTextSuffix"]
                  ;/* link + "/" + id */

                // console.log(" ele[keyResult] ", item[ele["keyResult"]]);


              }

              //*************************************************************
              //*************  type: concat2 ถ้าไม่มี ไม่ต้องแสดงเลย *************
              //*************************************************************

              else if (ele["type"] === "concat2") {

                      item[ele["useField1"]] = (item[ele["useField1"]] === null || item[ele["useField1"]] === undefined) ? "" : item[ele["useField1"]].toString();
                      item[ele["useField2"]] = (item[ele["useField2"]] === null || item[ele["useField2"]] === undefined) ? "" : item[ele["useField2"]].toString();

                      ele["mapTextPrefix"] = (ele["mapTextPrefix"] === null || ele["mapTextPrefix"] === undefined) ? "" : ele["mapTextPrefix"];
                      ele["mapTextBetween"] = (ele["mapTextBetween"] === null || ele["mapTextBetween"] === undefined) ? "" : ele["mapTextBetween"];
                      ele["mapTextSuffix"] = (ele["mapTextSuffix"] === null || ele["mapTextSuffix"] === undefined) ? "" : ele["mapTextSuffix"];

                      if (item[ele["useField1"]] === null || item[ele["useField1"]] === undefined  || item[ele["useField1"]] === "") {
                        item[ele["keyResult"]] = null
                      } else {

                        if (ele["field1Type"] === "datetime") {
                          item[ele["useField1"]] = formatDate(item[ele["useField1"]]);
                        }

                        if (ele["field2Type"] === "datetime") {
                          item[ele["useField2"]] = formatDate(item[ele["useField2"]]);
                        }

                          item[ele["keyResult"]] =
                          ele["mapTextPrefix"]
                        + item[ele["useField1"]]
                        + ele["mapTextBetween"]
                        + item[ele["useField2"]]
                        + ele["mapTextSuffix"]
                        ;/* link + "/" + id */

                      // console.log(" ele[keyResult] ", item[ele["keyResult"]]);
                      }




              }

              // console.log(" >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ");

              // console.log( "index : (", ind ,",",index , ") " , ele["keyResult"] ,  " :::::  item[ele[keyResult]] >>> ", item[ele["keyResult"]]);

              // console.log(" >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ");


            }

      });

      // console.log("%%%%%%%%%% transformList AFTER TRANSFORM >>> ",list);

      return list;

  };

export const getBestRole = (roles) => {
  var ro = null;

  ro =  roles.filter(item => item.roleName === "owner")

    if (ro !== null && ro !== undefined && ro.length > 0)
      return "owner";

    ro = roles.filter(item => item.roleName === "admin")
    if (ro !== null && ro !== undefined && ro.length > 0)
      return "admin";


    ro = roles.filter(item => item.roleName === "satff")
    if (ro !== null && ro !== undefined && ro.length > 0)
      return "satff";


    if (ro === null || ro === undefined) {
      return "staff";
    }

    return "staff";
}

// export const mapOrder = (array, order, key) => {
//   array.sort(function (a, b) {
//     var A = a[key]
//     var B = b[key]
//     if (order.indexOf(A + '') > order.indexOf(B + '')) {
//       return 1
//     } else {
//       return -1
//     }
//   })
//   return array
// }

// export const getDateWithFormat = () => {
//   const today = new Date()
//   let dd = today.getDate()
//   let mm = today.getMonth() + 1 // January is 0!

//   var yyyy = today.getFullYear()
//   if (dd < 10) {
//     dd = '0' + dd
//   }
//   if (mm < 10) {
//     mm = '0' + mm
//   }
//   return dd + '.' + mm + '.' + yyyy
// }

// export const getCurrentTime = () => {
//   const now = new Date()
//   return now.getHours() + ':' + now.getMinutes()
// }

// export const ThemeColors = () => {
//   let rootStyle = getComputedStyle(document.body)
//   return {
//     themeColor1: rootStyle.getPropertyValue('--theme-color-1').trim(),
//     themeColor2: rootStyle.getPropertyValue('--theme-color-2').trim(),
//     themeColor3: rootStyle.getPropertyValue('--theme-color-3').trim(),
//     themeColor4: rootStyle.getPropertyValue('--theme-color-4').trim(),
//     themeColor5: rootStyle.getPropertyValue('--theme-color-5').trim(),
//     themeColor6: rootStyle.getPropertyValue('--theme-color-6').trim(),
//     themeColor1_10: rootStyle.getPropertyValue('--theme-color-1-10').trim(),
//     themeColor2_10: rootStyle.getPropertyValue('--theme-color-2-10').trim(),
//     themeColor3_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
//     themeColor4_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
//     themeColor5_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
//     themeColor6_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
//     primaryColor: rootStyle.getPropertyValue('--primary-color').trim(),
//     foregroundColor: rootStyle.getPropertyValue('--foreground-color').trim(),
//     separatorColor: rootStyle.getPropertyValue('--separator-color').trim()
//   }
// }

// export const getDirection = () => {
//   let direction = defaultDirection
//   if (localStorage.getItem('direction')) {
//     const localValue = localStorage.getItem('direction')
//     if (localValue === 'rtl' || localValue === 'ltr') {
//       direction = localValue
//     }
//   }
//   return {
//     direction,
//     isRtl: direction === 'rtl'
//   }
// }

// export const setDirection = localValue => {
//   let direction = 'ltr'
//   if (localValue === 'rtl' || localValue === 'ltr') {
//     direction = localValue
//   }
//   localStorage.setItem('direction', direction)
// }
