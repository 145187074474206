
// ES6 Modules or TypeScript
// import Swal from 'sweetalert2'
export default {
  async UPDATE_TOKEN(state) {
    var tk = localStorage.getItem("token");
    var tkpaser = JSON.parse(tk);
    state.apiHeader = {
      headers: {
        Authorization: "bearer " + tkpaser
      }
    };

    // console.log(" (1) @@@@@ MUTATION UPDATE_TOKEN");
  },

  UPDATE_AUTHENTICATED_USER(state, user) {
    state.currentUser = user.username;
    state.currentUserImage = user.image;
    // console.log(" (1) @@@@@ MUTATION UPDATE_AUTHENTICATED_USER >>> ",user);
    localStorage.setItem("userInfo", JSON.stringify(user));

    // localStorage.setItem("userRole", "admin");
  },

  SET_USER_IMAGE(state, img) {
    state.currentUserImage = img;

  },
  SHOP_PACKAGE(state, pkg) {
    state.currentPackage = pkg;
  },

  async UPDATE_ROLES(state, payload) {


    // console.log("@@@@@@@@@@@ UPDATE_ROLES payload >>>> ",payload)

    var pm = payload.roles.filter(
      x =>
      x.roleName === "owner" ||
      x.roleName === "admin" ||
      x.roleName === "accountingadmin" ||
      x.roleName === "useradmin"
    );

    // console.log("@@@@@@@@@@@ UPDATE_ROLES pm >>>> ",pm)

    var permission = "staff";

    if (pm.length <= 0) {
      permission = "staff";
    } else {
      permission = pm[0]["roleName"];
    }

    // console.log("@@@@@@@@@@@ UPDATE_ROLES MY_PERMISSION >>>> ",permission)


    // if (pm.length >= 1) {
    //   permission = "admin";
    // } else {
    //   permission = "staff";
    // }




    // ***** เลือก BRANCH
    var branch_th = "1";
    var cb = [];

    if (payload.allbranches !== undefined && payload.allbranches !== null) {
      // หา main branch
      cb = payload.allbranches.filter(x => x.flag1 === "m");

    }

    if (cb.length <= 0) {
      cb[0] = "";
    }
    if (payload.branches.length <= 0) {
      payload.branches[0] = "";
    }


    // console.log('@@@@@@@@UPDATE_ROLES 2222 >>>> ',permission);

    var currentBranch;
    var mybranchid ;
    var myBranches;

    // เปลี่ยนเป็นเฉพาะ owner
    if (permission === "owner") {
      currentBranch = cb[0];
      mybranchid = cb[0].branchId;
      myBranches = payload.allbranches;

      // console.log('@@@ permission === "admin" >>>> ', payload.allbranches);


    } else {
      // หา branch ของ admin และ staff
      if (payload.branches.length > 0) {
        mybranchid = payload.branches[0].branchId;
        currentBranch = payload.branches[0];
        myBranches = payload.branches;

      } else {
        // console.log('cb[0] >>>>>>>>>>> ', cb[0]);
        currentBranch = cb[0];
        mybranchid = cb[0].branchId;
        myBranches = cb;
      }
    }

    if(state.currentBranch === null || state.currentBranch === undefined)
    {
      state.mybranchid = mybranchid;
      state.myBranches = myBranches;
      state.currentBranch = currentBranch;
    }

    state.mybranch = branch_th;
    state.roles = payload.roles;
    state.permission = permission;
    // console.log("@@@@@UPDATE_ROLES  BRANCHES ID >>> ", state.mybranchid);
    // console.log("@@@@@UPDATE_ROLES  CURRENT BRANCHES  >>> ", state.myBranches);
    // console.log("@@@@@UPDATE_ROLES store.state.auth.roles >>> ", state.roles);
    // console.log("@@@@@UPDATE_ROLES store.state.auth.permission >>> ", state.permission);
  },

  async CHANGE_CURRENT_BRANCH(state, payload) {

   console.log('START!!!!! payload', payload);

   var pm = payload.roles.filter(
    x =>
    x.roleName === "owner" ||
    x.roleName === "admin" ||
    x.roleName === "accountingadmin" ||
    x.roleName === "useradmin"
  );

  // console.log("@@@@@@@@@@@ CHANGE_BRANCH payload >>>> ",payload)

  // var permission = "staff";
  // if (pm.length >= 1) {
  //   permission = "admin";
  // } else {
  //   permission = "staff";
  // }

  var permission = "staff";

    if (pm.length <= 0) {
      permission = "staff";
    } else {
      permission = pm[0]["roleName"];
    }

    // console.log("@@@@@@@@@@@ CHANGE_CURRENT_BRANCH MY_PERMISSION >>>> ",permission)



  // ***** เลือก BRANCH
  // var branch_th = "1";
  var cb = [];

  // if (payload.allbranches !== undefined && payload.allbranches !== null) {
  //   // หา main branch
  //   mainBranch = payload.allbranches.filter(x => x.flag1 === "m");
  // }

  // if (cb.length <= 0) {
  //   cb[0] = "";
  // }
  // if (payload.branches.length <= 0) {
  //   payload.branches[0] = "";
  // }


  if (payload.allbranches !== undefined && payload.allbranches !== null) {
    // หา main branch
    cb = payload.allbranches.filter(x => x.flag1 === "m");

  }

  if (cb.length <= 0) {
    cb[0] = "";
  }
  if (payload.branches.length <= 0) {
    payload.branches[0] = "";
  }
  var currentBranch;
  var mybranchid ;
  var myBranches;


 // เปลี่ยนเป็นเฉพาะ owner
 if (permission === "owner") {

  cb = payload.allbranches.filter(x => x.branchId === payload.selectedBranchId);
    if(cb.length <= 0){
      // Swal.fire({
      //   icon: 'warning',
      //   title: 'ไม่สามารถเปลี่ยนสาขาได้ ลอง refresh browser อีกครั้ง',
      // });
      return;
    }

    state.currentBranch = cb[0];
    state.mybranchid = cb[0].branchId;
    state.myBranches = payload.allbranches;

  // console.log('@@@ permission === "admin" >>>> ', payload.allbranches);


} else {
  // หา branch ของ admin และ staff
  if (payload.branches.length > 0) {




        cb = payload.branches.filter(x => x.branchId === payload.selectedBranchId);
        if(cb.length <= 0){
          // Swal.fire({
          //   icon: 'warning',
          //   title: 'ไม่สามารถเปลี่ยนสาขาได้ ลอง refresh browser อีกครั้ง',
          // });
          return;
        }

        // console.log('@@@ CB[0] ', cb);


        state.mybranchid = cb[0].branchId;
        state.currentBranch = cb[0];
        state.myBranches = payload.branches;

        // console.log('@@@ หา branch ของ admin และ staff IF state.currentBranch >>>> ', state.currentBranch);
        // console.log('@@@ หา branch ของ admin และ staff IF state.mybranchid >>>> ', state.currentBranch);


  } else {
    // console.log('cb[0] >>>>>>>>>>> ', cb[0]);

    cb = payload.branches.filter(x => x.branchId === payload.selectedBranchId);
        if(cb.length <= 0){
          // Swal.fire({
          //   icon: 'warning',
          //   title: 'ไม่สามารถเปลี่ยนสาขาได้ ลอง refresh browser อีกครั้ง',
          // });
          return;
        }




    state.currentBranch = cb[0];
    state.mybranchid = cb[0].branchId;
    state.myBranches = payload.branches;

    // console.log('@@@ หา branch ของ admin และ staff ELSE state.currentBranch >>>> ', state.currentBranch);
    // console.log('@@@ หา branch ของ admin และ staff ELSE state.mybranchid >>>> ', state.currentBranch);
  }
}

if(state.currentBranch === null || state.currentBranch === undefined)
{
  state.mybranchid = mybranchid;
  state.myBranches = myBranches;
  state.currentBranch = currentBranch;
}

state.roles = payload.roles;
state.permission = permission;





  //*** ===========================================================  */

  // var cb = [];

  // var mainBranch = [];


  // // console.log('BEFORE IFFFFFFFFF >>>> ', payload);
  // // *** รวมกรณี admin ทั้งหลาย เปลี่ยนสาขาไหนก็ได้
  // if (permission === "admin") {
  //   // *** เปลี่ยนสาขาไหนก็ได้ allbranches
  //   cb = payload.allbranches.filter(x => x.branchId === payload.selectedBranchId);
  //   if(cb.length <= 0){
  //     // Swal.fire({
  //     //   icon: 'warning',
  //     //   title: 'ไม่สามารถเปลี่ยนสาขาได้ ลอง refresh browser อีกครั้ง',
  //     // });
  //     return;
  //   }

  //   state.currentBranch = cb[0];
  //   state.mybranchid = cb[0].branchId;
  //   state.myBranches = payload.allbranches;

  //   // console.log('@@@ permission === "admin" >>>> ', payload.allbranches);


  // } else {
  //   // console.log('@@@ ELSE IN CASE STAFF >>>> ');
  //   // *** กรณีของ staff ต้องเช็คก่อนว่า สังกัดใน branch หรือไม่
  //   // console.log('@@@ permission === "staff" >>>> ', payload.selectedBranchId);
  //   // console.log('@@@ payload.branches >>>> ',payload.branches);
  //   if (payload.branches.length > 0) {
  //     cb = payload.branches.filter(x => x.branchId === payload.selectedBranchId);
  //     console.log('@@@ IF payload.selectedBranchId >>>> ', payload.selectedBranchId);
  //     if(cb.length <= 0){
  //       // Swal.fire({
  //       //   icon: 'warning',
  //       //   title: 'ไม่สามารถเปลี่ยนสาขาได้ ลอง refresh browser อีกครั้ง',
  //       // });
  //       return;
  //     }

  //     console.log('@@@ CB[0] ', cb);
  //     state.mybranchid = cb[0].branchId;
  //     state.currentBranch = cb[0];
  //     state.myBranches = payload.branches;

  //   } else {
  //     // console.log('cb[0] >>>>>>>>>>> ', cb[0]);
  //     console.log('@@@ ELSE payload.selectedBranchId >>>> ', payload.selectedBranchId);
  //     state.currentBranch = mainBranch[0];
  //     state.mybranchid = mainBranch[0].branchId;
  //     state.myBranches = payload.branches;

  //   }
  // }

  //********************************************************************************************* */

  // state.mybranch = branch_th;
  state.roles = payload.roles;
  state.permission = permission;
  // console.log("@@@@@UPDATE_CHANGE_BRANCH  BRANCHES ID >>> ", state.mybranchid);
  // console.log("@@@@@UPDATE_CHANGE_BRANCH  MY BRANCHES  >>> ", state.myBranches);
  // console.log("@@@@@UPDATE_CHANGE_BRANCH  CURRENT BRANCHES  >>> ", state.currentBranch);
  // console.log("@@@@@UPDATE_CHANGE_BRANCH store.state.auth.roles >>> ", state.roles);
  // console.log("@@@@@UPDATE_CHANGE_BRANCH store.state.auth.permission >>> ", state.permission);


  },

  USER_LOGIN_SUCCESS(state) {
    state.isAuthen = true;
  },

  SET_USER_CURRENT_DOMAIN(state, item) {
    state.currentDomain = item;
  },

  USER_LOGIN_FAILED(state) {
    // this.$swal("Cancelled", "Your imaginary file is safe :)", "error");

    state.isAuthen = false;
  },
  USER_LOGOUT(state) {
    state.myBranches = [];
    // state.currentBranch = null;
    // state.mybranchid= null
    state.isAuthen = false;
  }
};
