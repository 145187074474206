

import axios from "@/axios.js"
import store from "@/store/store";
import Swal from 'sweetalert2'

export default {

fetch({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.post(store.state.apiURL + "/api/shopinfo/taxinfo", {} , store.state.auth.apiHeader)
        .then((response) => {
          commit('SET_SHOP_SETTING', response.data)
          resolve(response)
        })
        .catch((error) => {
          Swal.fire({
                    icon: 'warning',
                    title: "ไม่สามารถเรียกข้อมูลได้",
                    // text: err.response.state,
                  });
          reject(error)
        })
    })
  },
  updateShopSetting({
    commit
  }, item) {
    return new Promise((resolve, reject) => {
      axios.post(store.state.apiURL + "/api/shopinfo/changetax", item, store.state.auth.apiHeader)
        .then((response) => {
          // this.noticeSuccess("บันทึกสำเร็จ");
          commit('UPDATE_SHOP_SETTING', response.data);


          resolve(response)
        })
        .catch((error) => {
          Swal.fire({
            icon: 'warning',
            title: "ไม่สามารถอัพเดตการตั้งค่าได้",
            // text: err.response.state,
          });
          reject(error)
        })
    });
  },


}
