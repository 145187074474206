export default {
  cartItems: [],
  cartSubTotal: 0,
  cartRentalPrice: 0.00,
  cartBailPrice: 0.00,
  cartTotalPrice: 0.00,
  cartMobileReserveCheck: false,
  cartMobileReserveDate: {
    start: '',
    end: ''
  },
  checkRentalList: false,
  activeBtn: false,
  mainScreen: 1300,
};
