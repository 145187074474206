

import axios from "@/axios.js"
import store from "@/store/store";
import Swal from 'sweetalert2'

export default {
  async saveChange() {
    // commit('SAVE_CHANGE');
    var formData = new FormData();
    // console.log('*********** images *********** ', store.state.productList.images);


    for (var i = 0; i < store.state.productList.images.length; i++) {
      formData.append('files[' + i + ']', store.state.productList.images[i]);
    }

    try {
      await axios.post(
        store.state.apiURL + "/api/test-upload/" + "XXXXX-XXXXXXX-XXX",
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        // store.state.auth.apiHeader
      );
    } catch (err) {
      console.log('err >>> ', err);

      Swal.fire({
        icon: 'error',
        title: 'มียางอย่างผิดพลาด',
        // text: 'Something went wrong!',
      });
    }


  },


  async add({
    commit
  }, item) {

    // /api/v2/orderheader/clean-fix/addWnfShop

     var res = null;
        try {
          res = await axios.post(
           store.state.apiURL + "/api/v2/orderheader/clean-fix/addWnfShop",
            item,
            store.state.auth.apiHeader
          );

           commit('SET_PRODUCTS', res.data);

        } catch (error) {
          res = null;
           Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        // text: 'Something went wrong!',
      });

        }

    commit('ADD_ITEM', item)
  },

  async sendToWashFromStock(
    {commit},
    item) {
    // var res = null;
    // console.log("sendToWashFromStock ACTION >>> ", item);
        try {
          // res =
            await axios.post(
           store.state.apiURL + "/api/v2/stock/send-to-wnf",
            item,
            store.state.auth.apiHeader
          );

           commit('CLEAR_SEARCH_PARAM');

        } catch (error) {
          // res = null;
           Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        // text: 'Something went wrong!',
      });

    }

  },

  async sendToWash({
      commit
  }, item) {

        var res = null;
        try {
          res = await axios.post(
           store.state.apiURL + "/api/v2/orderheader/clean-fix/send-to-wnf",
            item,
            store.state.auth.apiHeader
          );

           commit('SET_PRODUCTS', res.data);

        } catch (error) {
          res = null;
           Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        // text: 'Something went wrong!',
      });

        }
  },

  async getOrderHeader({
      commit
  }) {

        var res = null;
        try {
          res = await axios.get(
           store.state.apiURL + "/api/orderheader",
            store.state.auth.apiHeader
          );

           commit('SET_ORDERHEADER', res.data);

        } catch (error) {
          res = null;
           Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถรับข้อมูลได้',
      });

        }
    },

  async getOrderDetail({
      commit
  }) {

        var res = null;
        try {
          res = await axios.get(
           store.state.apiURL + "/api/orderdetail",
            store.state.auth.apiHeader
          );

           commit('SET_ORDERDETAIL', res.data);

        } catch (error) {
          res = null;
           Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถรับข้อมูลได้',
      });

        }
    },


  async receiveFromWash({
      commit
  }, item) {

        var res = null;
        try {
          res = await axios.post(
           store.state.apiURL + "/api/v2/orderheader/clean-fix/receive-from-wnf",
            item,
            store.state.auth.apiHeader
          );

           commit('SET_PRODUCTS', res.data);

        } catch (error) {
          res = null;
           Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        // text: 'Something went wrong!',
      });

        }
    },
  async notToSend({
      commit
  }, item) {

        var res = null;
        try {
          res = await axios.post(
           store.state.apiURL + "/api/v2/orderheader/clean-fix/not-send-to-wnf",
            item,
            store.state.auth.apiHeader
          );

           commit('SET_PRODUCTS', res.data);

        } catch (error) {
          res = null;
           Swal.fire({
        icon: 'warning',
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        // text: 'Something went wrong!',
      });

        }
    },


  clearParm({
    commit
  }) {

    commit('CLEAR_SEARCH_PARAM')
  },

  searchParm({
    commit
  }, item) {

    commit('ADD_SEARCH_PARAM', item)
  }
  ,

  addSelected({
    commit
  }, item) {
    commit('ADD_SELECTED', item);

    var selected = store.getters["washingList/selected"];

    var fil = selected.filter(x => x.orderDetailId === item.orderDetailId);

    if (fil.length > 0) return true;
    else return false;

  },

  selectedAll({
    commit
  }, list) {

    commit('SELECTED_All', list);
  },

  unSelectedAll({
    commit
  }, list) {

    commit('UNSELECTED_All', list);
  },

  clearAll({
    commit
  }) {

    commit('CLEAR_ALL');
  },



  applyAll({
      commit
    },
    item) {
    commit('APPLY_ALL', item);
    console.log('item in APPLY  ALL ', item);


  },

  async addImage({
    commit
  }, item) {
    commit('ADD_IMAGE', item)

  },
  async fetchColorList({
    commit
  }, item) {
    var res = await axios.post(
      store.state.apiURL +
      "/api/productgroup/load-color/" +
      item, {},
      store.state.auth.apiHeader
    );

    // console.log("##########fetchColorList ############# >>>>> ",res);
    commit('SET_IMAGE', res.data)
  },
  fetchList({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      axios.post(store.state.apiURL + "/api/v2/orderheader/clean-fix/filter",params, store.state.auth.apiHeader)
        .then((response) => {
          commit('SET_PRODUCTS', response.data)
          resolve(response)
        })
        .catch((error) => {
          this.$swal({
            icon: 'error',
            title: 'ไม่สามารถรับข้อมูลได้',
          });
          reject(error)
        })
    })
  },
  listInGroup({
    commit
  }, item) {
    return new Promise((resolve, reject) => {
      axios.post(store.state.apiURL + "/api/productitem/group/" + item, {}, store.state.auth.apiHeader)
        .then((response) => {
          commit('SET_PRODUCTS', response.data)
          // console.log('listInGroup ??? response.data', response.data);

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateList({
    commit
  }, list) {

    commit('SET_PRODUCTS', list)

  },

  update({
    commit
  }, item) {
    return new Promise((resolve, reject) => {
      axios.put(store.state.apiURL + "/api/productitem/" + item, item, store.state.auth.apiHeader)
        .then((response) => {
          commit('UPDATE_PRODUCT', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  remove({
    commit
  }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(store.state.apiURL + "/api/productitem/" + itemId, store.state.auth.apiHeader)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeImage({
    commit
  }, item) {
    commit('REMOVE_IMAGE', item)
    console.log('IMAGE IN PRODUCT_LIST >>> ', store.state.productGroup.productGroup);


  },

  removeAll({
    commit
  }) {
    // console.log('REMOVE_ALL >>>***(**(');

    commit('REMOVE_ALL')

  },

}
