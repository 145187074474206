
import Vue from "vue";
import App from "./App.vue";
// import th from 'dayspan-vuetify/src/locales/th';

//***  NEW CALENDAR DAYSPAN
import Vuetify from 'vuetify'
import DaySpanVuetify from 'dayspan-vuetify'

// import 'vuetify/dist/vuetify.min.css'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
// import 'dayspan-vuetify/dist/lib/dayspan-vuetify.min.css'

Vue.use(Vuetify);

Vue.use(DaySpanVuetify, {
  methods: {
    getDefaultEventColor: () => '#1976d2'
  }
});

Vue.$dayspan.addLocales(['th']);

//*** NEW CALENDAR DAYSPAN

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;

// API Calls
import "./http/requests";

// mock
import "./fake-db/index.js";

// Theme Configurations
import "../themeConfig.js";

// Firebase
// import "@/firebase/firebaseConfig";

// Auth0 Plugin
import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);

import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
// ACL
import acl from "./acl/acl";

// Globally Registered Components
import "./globalComponents.js";
import VCalendar from "v-calendar";
Vue.use(VCalendar, {
  // ...some defaults
  screens: {
    tablet: "576px",
    laptop: "992px",
    desktop: "1200px"
  }
  // ...other defaults
});

// Styles: SCSS
import "@/assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// i18n
import i18n from "./i18n/i18n";

// Vuexy Admin Filters
import "./filters/filters";

import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

// Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// Tour
import VueTour from "vue-tour";
Vue.use(VueTour);
require("vue-tour/dist/vue-tour.css");

// VeeValidate
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

// Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: "YOUR_API_KEY",
    libraries: "places" // This is required if you use the Auto complete plug-in
  }
});

// FONT AWESOME
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);

Vue.component("font-awesome-icon", FontAwesomeIcon);

// /FONT AWESOME

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";


import VueCompositionAPI from '@vue/composition-api'
Vue.use (VueCompositionAPI)




// Feather font icon
require("./assets/css/iconfont.css");

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

// const getRuntimeConfig = async () => {
//   const runtimeConfig = await fetch('/runtimeConfig.json');
//   return await runtimeConfig.json()
// }

// getRuntimeConfig().then(function(json) {
//   console.log("Runtime config: " + JSON.stringify(json))

// });
import {calcPagination, dateFormat, dateFormatTime, customAxios} from './helper'
Vue.prototype.$calcPagination = calcPagination;
Vue.prototype.$dateFormat = dateFormat;
Vue.prototype.$dateFormatTime = dateFormatTime;
Vue.prototype.$customAxios = customAxios;


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount("#app");
