

import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList";
import themeConfig from "@/../themeConfig.js";
// import apiUrl from "@/../themeConfig.js";
// import vueConfig from "@/../vue.config.js"
import colors from "@/../themeConfig.js";


// const imgURL = process.env.NODE_ENV === 'production'
// ? 'https://demo002.alexrental.app'
// : 'https://demo002.alexrental.app';


// const baseURL = process.env.NODE_ENV === 'production'
// ? 'https://demo002.alexrental.app'
// : 'https://localhost:5001';

const baseURL = process.env.VUE_APP_API;
const imgUrl = process.env.VUE_APP_IMAGE_URL;

var tmp = localStorage.getItem('currentDomain');
var currentDomain = JSON.parse(tmp);


const imageURL = imgUrl + "/";
const regURL = "http://register.alexrental.app";


const state = {
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  mainLayoutType: themeConfig.mainLayoutType || "vertical",
  navbarSearchAndPinList: navbarSearchAndPinList,
  reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: "default",
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  starredPages: navbarSearchAndPinList["pages"].data.filter(
    page => page.is_bookmarked
  ),
  theme: themeConfig.theme || "light",
  themePrimaryColor: colors.primary,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,

  // --------------------------------------------------------------
  regURL: regURL,
  apiURL: baseURL,
  baseImageStore: imageURL + `images/${currentDomain}/`,
  imageStore: imageURL + `images/${currentDomain}/item/`,
  webImage: imageURL + `webimage/`,
  testStore: imageURL + `images/test/`,
  reviewStore: imageURL + `images/${currentDomain}/review/`,
  blogStore: imageURL + `images/${currentDomain}/blog/`,
  barcodeStore: imageURL + `images/barcode/`,
  slipStore: imageURL + `images/${currentDomain}/slip/`,
  defaultStore: imageURL + `images/${currentDomain}/`,
  userImageStore: imageURL + `images/${currentDomain}/user/`,
  // defaultUserImage: imageURL + `images/${currentDomain}/defaultUserImage.png`,
  // defaultImage: imageURL + `images/${currentDomain}/defaultimage.png`,
  defaultUserImage: imageURL + `images/newShop/defaultUserImage.png`,
  defaultImage: imageURL + `images/newShop/defaultimage.png`,

  // --------------------------------------------------------------
  currentUser: "",
  currentUserRole: "",
  currentUserImage: "",
  // --------------------------------------------------------------
  isSidebarActive: true,
  breakpoint: null,
  sidebarWidth: "default",
  sidebarItemsMin: false,
  AppActiveUser: {
    id: 0,
    name: "John Doe",
    about: "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.",
    img: "avatar-s-11.png",
    status: "online"
  },
  thai: {
    "day-names": ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
    "check-in": "วันเริ่ม",
    "check-out": "วันสุดท้าย",
    "month-names": [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม"
    ]
  },
  notinumber: 0,

  // starredPages: navbarSearchAndPinList.data.filter(
  //   page => page.highlightAction
  // ),
  userRole: null,
  calendarConfigs: {
    dateFormat: "yyyy-mm-dd",
    changeMonthFunction: true,
    changeYearFunction: true,
    isModal: true,
    isDateRange: true
  }
};

export default state;
