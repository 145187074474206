/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {

  searchParameters: {},
  currentId : "",
  itemId: "rentDurationId",
  items: [],
  selected: [],
  transform:
    [


      // {
      //   type: "concat",
      //   keyResult: "used",
      //   default: "ไม่มีข้อมูล",
      //   mapTextPrefix: "ใช้ไป: ",
      //   // mapTextBetween: "/",
      //   // mapTextSuffix: "<br>",
      //   useField1: "usedAmount"
      //   // useField2 : ""

      // },
      {
        type: "concat",
        keyResult: "rentDayStr",
        default: "ไม่มีข้อมูล",
        // mapTextPrefix: "",
        // mapTextBetween: "/",
        mapTextSuffix: " วัน",
        useField1: "rentDays"
        // useField2 : ""
      },

      {
        type: "concat2",
         keyResult: "updateDate",
         default: "",
          mapTextPrefix: "",
          useField1: "updateDateTime",
          field1Type: "datetime"
        // mapTextBetween: "/",
        // useField2 : "",
        // mapTextSuffix: "/",

      },

      // {
      //   type: "link",
      //   keyResult: "campaignlink",
      //   defaultTo: "/",
      //   mapUrl: "/campaign-detail",
      //   mapText: "/",
      //   useField: "uid",

      // },
    ]
  ,

}
