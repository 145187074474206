import router from "@/router";
import store from "@/store/store";

import Vue from "vue";

import {
  AclInstaller,
  AclCreate,
  AclRule
} from "vue-acl";

Vue.use(AclInstaller);

let initialRole = "admin";
initialRole = localStorage.getItem("roles");

// console.log("localStorage initialRole >>> ", initialRole);

var roles = [];
if (initialRole) {
  roles = JSON.parse(initialRole);
} else {
  roles = [{
    roleName: "staff"
  }];
}



var pm = roles.filter(
  x =>
  x.roleName === "owner" ||
  x.roleName === "admin" ||
  x.roleName === "accountingadmin" ||
  x.roleName === "useradmin"
);

var permission = "staff";
if (pm.length >= 1) {
  permission = "admin";
} else {
  permission = "staff";
}

initialRole = permission;

// console.log("pm.length >>>", pm.length);

// console.log("initialRole >>>", initialRole, "<<< permission >>>", permission);

export default new AclCreate({
  initial: initialRole,
  notfound: "/pages/not-authorized",
  router,
  acceptLocalRules: true,
  globalRules: {
    owner: new AclRule("owner").generate(),
    admin: new AclRule("admin").or("owner").generate(),

    staff: new AclRule("staff")
      .or("admin")
      .or("owner")
      .generate(),
    onlyStaff: new AclRule("staff").generate()
    // public: new AclRule('public').or('admin').or('editor').generate(),
  },

  middleware: async acl => {
    // console.log(" (0.) @@@@@ acl.js middleware");
    await store.dispatch("auth/getAuth"); // call your api
    // console.log("HELOOOOO I AMMMMMM MIDDLEWAREEEEEEEEEEE");
    var roles = [];
    if (store.state.auth.roles) {
      roles = await store.state.auth.roles;
    } else {
      roles = [{
        roleName: "staff"
      }];
    }

    var pm = roles.filter(
      x =>
      x.roleName === "owner" ||
      x.roleName === "admin" ||
      x.roleName === "accountingadmin" ||
      x.roleName === "useradmin"
    );

    var po = roles.filter(xx => xx.roleName === "owner");

    var permission = "staff";
    if (pm.length >= 1) {
      if (po.length >= 1) permission = "owner";
      else permission = "admin";
    } else {
      permission = "staff";
    }
    acl.change(permission);
    // console.log(
    //   "@@@@@@@@@@@@@@@@@@@@@@@@@@@ acl.js MIDDLEWARE >>>>>>>> ",
    //   permission
    // );
  }

});
