
import axios from "@/axios.js"
import store from "@/store/store";
import Swal from 'sweetalert2'

export default {



  async getAllData({
    commit
  }, item) {

    console.log('@@@@@@@@ getAllData yyyy >>> ',item);
    var res = null;
    try {
      res = await axios.post(store.state.apiURL + "/api/orderheader/chart/monthly-income" , item, store.state.auth.apiHeader);
      console.log('@@@@@@@@ getAllData  res.data >>> ', res.data);
      commit('SET_DATA', res.data);

    } catch (err) {

      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถเรียกข้อมูลได้',
      });
      res = null
    }
  },


  async getSellingData({
    commit
  }, item) {


    var res = null;
    try {
      res = await axios.post(store.state.apiURL + "/api/orderheader/chart/monthly-sell-stat" , item, store.state.auth.apiHeader);
      commit('SET_SELLING_DATA', res.data);

    } catch (err) {

      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถเรียกข้อมูลได้',
      });
      res = null
    }
  },

}
