/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {

  searchParameters: {},
  basket: [],
  selected: [],
  images: [],
  wnfShop: [],
  display: {},
  anOrder: {},
  wnfDetail: {},
  displayPopup: false,
  updateItem: {}
}
