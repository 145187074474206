

import axios from "@/axios.js"
import store from "@/store/store";
import Swal from 'sweetalert2'

export default {
  
  fetchList({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      axios.post(store.state.apiURL + "/api/v2/orderheader/clean-fix/wnf-order",params, store.state.auth.apiHeader)
        .then((response) => {
          commit('SET_PRODUCTS', response.data)
          resolve(response)
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'ไม่สามารถรับข้อมูลได้',
          });
          reject(error)
        })
    })
  },

  anOrder({
    commit
  }, params) {
    return new Promise((resolve, reject) => {
      axios.post(store.state.apiURL + "/api/v2/orderheader/clean-fix/single-wnf-order",params, store.state.auth.apiHeader)
        .then((response) => {
          commit('SET_ITEM', response.data)
          resolve(response)
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'ไม่สามารถรับข้อมูลได้',
          });
          reject(error)
        })
    })
  },
  

}
