/*=========================================================================================
  File Name: moduleDataList.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Author: Chaloemchai.B
==========================================================================================*/


import state from './moduleDurationState.js'
import mutations from './moduleDurationMutations.js'
import actions from './moduleDurationActions.js'
import getters from './moduleDurationGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
