/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  allCodeInfo: {},
  categories: [],
  textures: [],
  colors: [],
  sizes: [],
  dataList: {},
  productGroup: {
    name: "New item",
    code: "",
    mainImage: "",
    category: "",
    texture: "",
    colors: [],
    sizes: [],
    qty: 0,
    yearth: 1,
    gender: "W",
    itemAmount: 1,
    rentalPrice: 0,
    bail: 0,
    itemStatus: "เปิด",
    webCategory: "",
    webHotItem: "ไม่ใช่",
    webItem: "n",
    itemCode3Digit: "000",
    color: "",
    newRecord: true,
    temporary: false,
    state: true,
    updateBy: new Date,
    createBy: new Date


  },

}
