/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import {
  automapper
} from '@/utils'

export default {
  // getItem: state => (productId) => state.products.find((product) => product.id == productId),
  productGroup: state => state.productGroup,
  dataList: state => state.dataList,
  allCodeInfo: state => state.allCodeInfo,
  categories: state => automapper(state.categories, ["code", "description"]),
  textures: state => automapper(state.textures, ["code", "description"]),
  colors: state => automapper(state.colors, ["code", "description"]),
  sizes: state => automapper(state.sizes, ["code", "description"]),

}
