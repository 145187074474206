/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  // productGroup: {
  //   name: "",
  //   code: "",
  //   mainImage: "",
  //   category: "",
  //   texture: "",
  //   retalPrice: 0,
  //   bail: 0,
  //   colors: [],
  //   sizes: [],

  // },
  searchParameters: {},
  products: [],
  selected: [],
  catalog: [],
  catalogPanel: false,
  images: [],
  somethingChange: 0,
}
