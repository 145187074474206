import state from "./cartSaleState";
import mutations from "./cartSaleMutations";
import actions from "./cartSaleActions";
import getters from "./cartSaleGetters";

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
