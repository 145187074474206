export default {
  sidebarRightCreateProductToggle(state, data) {
    state.sidebarRightCreateProduct = data
    // console.log("@@@@@ COMMIT sidebarRightCreateProductToggle >>> ",data)
  },
  sidebarRightCreateVoucherToggle(state, data) {
    state.sidebarRightCreateVoucher = data
  },

  addDurationSideBarToggle(state, data) {
    state.addDurationSideBar = data
  },

  updatedDurationSideBarToggle(state, data) {
    console.log("@@@@@ COMMIT updatedDurationSideBarToggle >>> ",data)
    state.updateDurationSideBar = data
  },

  allSidebarClose(state) {
    state.addDurationSideBar = false;
    state.updateDurationSideBar = false;
  },
};
