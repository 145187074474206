
import {
  getBestRole
} from '@/utils'


export default {
  currentUserImage: state => state.currentUserImage,
  currentUser: state => state.currentUser,
  myBranches : state => state.myBranches,
currentBranch : state => state.currentBranch,
  currentPackage : state => state.currentPackage,
  permission: state => state.permission,
  getRole: state => getBestRole(state.roles),


}
