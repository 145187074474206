/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.productGroup.unshift(item)
  },
  SET_DATA(state, item) {
    Object.assign(state.categories, item.categories);
    Object.assign(state.textures, item.textures);
    Object.assign(state.colors, item.colors);
    Object.assign(state.sizes, item.sizes);
    Object.assign(state.allCodeInfo, item);


  },

  SET_ITEM(state, item) {
    Object.assign(state.productGroup, item)
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_ITEM(state, product) {
    const productIndex = state.productGroup.findIndex((p) => p.id == product.id)
    Object.assign(state.productGroup[productIndex], product)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.productGroup.findIndex((p) => p.id == itemId)
    state.productGroup.splice(ItemIndex, 1)
  },

  REMOVE_ALL(state) {
    state.productGroup = []
  },
}
