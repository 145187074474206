export default {
  changeMainScreen(state, data) {
    state.mainScreen = data;
  },
  resetCart(state) {
    state.cartItems= [];
    state.cartSubTotal= 0;
    state.cartRentalPrice= 0.00;
    state.cartBailPrice= 0.00;
    state.cartTotalPrice= 0.00;
    state.cartMobileReserveCheck= false;

    state.checkRentalList= false;
    state.activeBtn = false;
  },
  checkRentalListInput(state, data) {
    state.checkRentalList = data;
  },
  activeBtnInput(state, data) {
    state.activeBtn = data;
  },
    addCartItem(state, data) {
        const index = state.cartItems.findIndex(item => item.id === data.id)

        if (index > -1) {
            // update qty
            state.cartItems[index].info.quantity = state.cartItems[index].info.quantity + 1
        } else {
            // add new item
            data.info.quantity = 1
            data.info.notAvailable = false
            state.cartItems.push(data)
        }
    },
    removeCartItem(state, data) {
        state.cartItems = state.cartItems.filter(item => item.id != data.id);
    },
    updateCartTotalPrice(state) {
        state.cartRentalPrice = 0
        state.cartBailPrice = 0
        state.cartTotalPrice = 0

        let cartRentalPrice = 0
        let cartBailPrice = 0

        state.cartItems.forEach(item => {
          if (item.info.rentalPrice === undefined && item.info.bail === undefined) {
            cartRentalPrice = cartRentalPrice + (item.info.price * item.info.quantity)
          } else {
            cartRentalPrice = cartRentalPrice + (item.info.rentalPrice * item.info.quantity)
            cartBailPrice = cartBailPrice + (item.info.bail * item.info.quantity)
          }
        })

        state.cartRentalPrice = cartRentalPrice
        state.cartBailPrice = cartBailPrice
        state.cartTotalPrice = cartRentalPrice + cartBailPrice
    },
    updateQtyCart(state, data) {
        const index = state.cartItems.findIndex(item => item.id === data.id)

        if (index > -1) {
            // update qty
            state.cartItems[index].info.quantity = data.quantity
        }
    },
    triggerProductNotAvailable(state, data) {

      for (let index = 0; index < data.length; index++) {
        if (data[index].result === 1) {
          state.cartItems[index].info.notAvailable = false;
        } else {
          state.cartItems[index].info.notAvailable = true;
        }

      }



      // const index = state.cartItems.findIndex(item => item.id === data.id)

      // if (index > -1) {
      //     state.cartItems[index].info.notAvailable = true
      // }
    },
    updateCartMobileReserveCheck(state, data) {
      state.cartMobileReserveCheck = data
    },
    updateCartMobileReserveDate(state, data) {
      state.cartMobileReserveDate = data
    }
};
