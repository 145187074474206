/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  // SAVE_CHANGE(state) {

  // },
  ADD_ITEM(state, item) {
    state.basket.unshift(item)
  },
  ADD_IMAGE(state, item) {

    state.images.unshift(item);


  },


  ADD_SEARCH_PARAM(state, item) {
    Object.assign(state.searchParameters, item);
  },
  CLEAR_SEARCH_PARAM(state) {
    state.searchParameters = {};
  },



  ADD_SELECTED(state, item) {

    var fil = state.selected.filter(x => x.orderDetailId === item.orderDetailId);
    if (fil.length < 1) {
      state.selected.push(item);
    } else {
      const ItemIndex = state.selected.findIndex((p) => p.orderDetailId == item.orderDetailId)
      state.selected.splice(ItemIndex, 1);
    }

    // console.log('@@@@@@@ MUTUAL >> state.selected ', state.selected);

  },

  SELECTED_All(state, list) {
    var indList = [];
    state.selected.forEach((ele, index) => {
      var chk = list.filter(x => x.orderDetailId === ele.orderDetailId);
      if (chk.length > 0) {
        indList.push(index);
      }
    });


    for (let ind = indList.length - 1; ind >= 0; ind--) {
      state.selected.splice(indList[ind], 1);

    }

    state.selected.push(...list);

  },

  UNSELECTED_All(state, list) {
    var indList = [];
    state.selected.forEach((ele, index) => {
      var chk = list.filter(x => x.orderDetailId === ele.orderDetailId);
      if (chk.length > 0) {
        indList.push(index);
      }
    });

    for (let ind = indList.length - 1; ind >= 0; ind--) {
      state.selected.splice(indList[ind], 1);

    }

  },

  CLEAR_ALL(state) {
    state.selected = [];
    state.selectedNumber = 0;

  },


  SET_PRODUCTS(state, basket) {
    Object.assign(state.basket, basket.itemList);
    Object.assign(state.display, basket)

    // console.log("state.display >>> ",state.display);

  },

  SET_ITEM(state, item) {
    Object.assign(state.anOrder, item);

    // console.log("state.display >>> ",state.display);

  },

  SET_WNF_DETAIL(state, item) {
    Object.assign(state.wnfDetail, item);

  },

  POP_PANEL(state, item) {
    // Object.assign(state.basket, basket.itemList);
    // Object.assign(state.display, basket)
    state.displayPopup = item;
  },

UPDATE_ITEM(state, item) {
    state.updateItem = item;
  },
  SET_WNFSHOP(state, item) {
    Object.assign(state.wnfShop, item);
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_PRODUCT(state, item) {
    const productIndex = state.products.findIndex((p) => p.id == item.id)
    Object.assign(state.products[productIndex], item)
  },
  REMOVE_IMAGE(state, item) {
    state.images.unshift(item)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.products.findIndex((p) => p.id == itemId)
    state.products.splice(ItemIndex, 1)
  },
  REMOVE_ALL(state) {
    state.products = [];
  },
}
